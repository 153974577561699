import { isEmpty, isMobile } from '../utils/helper';
import { pushVirtualPageview, trackButtonEvent } from '../utils/analytics';

class GbFooter extends HTMLElement {
  constructor() {
    super();
    this._content = {};
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleAnchorClick = this.handleAnchorClick.bind(this);
    this.update = this.update.bind(this);
    this.render = this.render.bind(this);
    this.afterConnectedCallback = false;
  }

  set content(value) {
    this._content = value;
    this.afterConnectedCallback && this.update();
  }

  get content() {
    return this._content;
  }

  handleButtonClick() {
    let template = document.querySelector('gb-tutorial');
    template.classList.remove('gb-tutorial--hidden');

    // Tracking of click in footer only for tutotrial
    pushVirtualPageview('how-to-use', 'HOW TO USE');
  }

  handleAnchorClick(label) {
    return () => trackButtonEvent(label);
  }

  update() {
    const { instructions, imprint, privacy } = this._content;
    let btn = this.querySelector('button');
    // if component not rendered yet
    if (isEmpty(btn)) {
      this.render();
      return;
    }
    btn.innerText = instructions.label;
    let links = this.getElementsByTagName('a');
    links[0].innerText = imprint.label;
    links[1].innerText = privacy.label;
  }

  render() {
    if (isEmpty(this._content)) {
      return;
    }
    const { instructions, imprint, privacy } = this._content;
    this.innerHTML = `<footer class='c-footer'>
                            <div class='c-footer__wrapper'>
                                <div class='c-footer__nav'>
                                   <button>${instructions.label}</button>
                                   <a href='${imprint.url}' id='imprint' target='_blank'>${imprint.label}</a>
                                   <a href='${privacy.url}' id='privacy' target='_blank'>${privacy.label}</a>
                                </div>
                            </div> 
                          </footer>`;
  }

  connectedCallback() {
    this.render();
    this.afterConnectedCallback = true;

    let btn = this.querySelector('button');
    btn.addEventListener('click', this.handleButtonClick);

    let anchor = this.querySelectorAll('a');
    if (anchor) {
      anchor.forEach((label) => {
        label.addEventListener('click', this.handleAnchorClick(label.id));
      });
    }
  }

  disconnectedCallback() {
    let btn = this.querySelector('button');
    btn.removeEventListener('click', this.handleButtonClick);

    let anchor = this.querySelector('a');
    anchor.removeEventListener('click', this.handleAnchorClick);
  }
}

customElements.define('gb-footer', GbFooter);
