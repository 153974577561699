import { isEmpty } from '../utils/helper.js';

class GbContentElement extends HTMLElement {
    constructor() {
        super();
        this.update = this.update.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this._content = {};
    }

    set content(value) {
        this._content = value;
        this.update();
    }

    get content() {
        return this._content;
    }

    connectedCallback() {
        this.className = 'gb-content-element';
    }

    update() {
        this.innerHTML = this.renderContent();
    }

    renderContent() {
        const { image, title, text, list} = this._content;
        let div = document.createElement('div');
        div.innerHTML = `<img src="${image.url}" alt="${image.alt}" />
                         <div class="gb-content-element__text">
                            <h3>${title}</h3>
                            ${!isEmpty(text) ? this.renderText(text) : ''}
                            ${!isEmpty(list) ? this.renderList(list) : ''}
                         </div>
                         `;
        return div.innerHTML;
    }

    renderText(text) {
        let p = document.createElement('p');
        p.innerHTML = text;
        return p.outerHTML;
    }

    renderList(list) {
        let ul = document.createElement('ul');
        list.forEach(text => {ul.innerHTML += `<li>${text}</li>`;});
        return ul.outerHTML;
    }
}

customElements.define('gb-content-element', GbContentElement);