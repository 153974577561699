import { epsilon, approximately, clamp, smoothDamp } from '../../utils/mathf';
import * as THREE from 'three';

AFRAME.registerComponent('camera-controls', {
  dependencies: ['position', 'rotation'],

  constants: {
    // constant ids to rotate from external sources
    rotation: {
      NONE: 0,
      LEFT: 1 << 0,
      RIGHT: 1 << 1,
      UP: 1 << 2,
      DOWN: 1 << 3,
    },
  },

  schema: {
    enabled: { default: true },
    pointerLockEnabled: { default: false },
    reverseMouseDrag: { default: true },
    reverseTouchDrag: { default: false },
    mouseSensitivity: { default: 2.5 },
    touchSensitivity: { default: 2.0 },
    zoomSensitivity: { default: 0.0005 },
    zoomSpeed: { default: 1.5 },
    verticalAngle: { default: 90.0 },
    lookAtDuration: { default: 0.5 },
    externalSensitivity: { default: 1.0 },
    minZoom: { default: 1.0 },
    maxZoom: { default: 2.0 },
  },

  init: function () {
    this.savedPose = null;
    this.pointerLocked = false;

    this.hasLookAtTarget = false;
    this.lookAtProgress = 0.0;
    this.lookAtTarget = new THREE.Quaternion();
    this.lookAtOrigin = new THREE.Quaternion();

    this.targetZoom = 1.0;
    this.zoomVelocity = 0.0;

    this.externalRotationData = {
      x: {
        current: 0.0,
        target: 0.0,
        velocity: 0.0,
        timer: 0.0,
      },
      y: {
        current: 0.0,
        target: 0.0,
        velocity: 0.0,
        timer: 0.0,
      },
    };

    this.externalRotation = this.constants.rotation.NONE;
    this.updatedEvent = new CustomEvent('cameraupdated', null);

    this.setupControls();
    this.bindMethods();

    this.savedPose = {
      position: new THREE.Vector3(),
      rotation: new THREE.Euler(),
    };
  },

  update: function (oldData) {
    let data = this.data;

    if (data.enabled !== oldData.enabled) {
      this.updateGrabCursor(data.enabled);
    }

    if (oldData && !data.pointerLockEnabled !== oldData.pointerLockEnabled) {
      this.removeEventListeners();
      this.addEventListeners();

      if (this.pointerLocked) {
        this.exitPointerLock();
      }
    }
  },

  tick: function (time, timeDelta) {
    let data = this.data;
    let dt = timeDelta / 1000.0;

    if (!data.enabled) {
      return;
    }

    let isDragged = this.mouseDown || this.touchStarted;
    let hasExternalInput = this.externalRotation !== this.constants.rotation.NONE;
    let hasExternalMotion =
      !approximately(this.externalRotationData.x.current, 0.0) ||
      !approximately(this.externalRotationData.x.current, 0.0);
    let hasLookAt = this.hasLookAtTarget;

    // update controls based on the following priority:
    // 1. mouse/touch drag
    // 2. external buttons/events
    // 3. look at calls
    if (isDragged) {
      this.updateDragOrientation();
    } else if (hasExternalInput || (hasExternalMotion && !hasLookAt)) {
      this.updateExternalOrientation(dt);
    } else if (hasLookAt) {
      this.updateLookAtOrientation(dt);
    }

    this.updateZoom(dt);
  },

  play: function () {
    this.addEventListeners();
  },

  pause: function () {
    this.removeEventListeners();

    if (this.pointerLocked) {
      this.exitPointerLock();
    }
  },

  remove: function () {
    this.removeEventListeners();

    if (this.pointerLocked) {
      this.exitPointerLock();
    }
  },

  bindMethods: function () {
    this.onMouseDown = AFRAME.utils.bind(this.onMouseDown, this);
    this.onMouseMove = AFRAME.utils.bind(this.onMouseMove, this);
    this.onMouseUp = AFRAME.utils.bind(this.onMouseUp, this);
    this.onMouseWheel = AFRAME.utils.bind(this.onMouseWheel, this);
    this.onTouchStart = AFRAME.utils.bind(this.onTouchStart, this);
    this.onTouchMove = AFRAME.utils.bind(this.onTouchMove, this);
    this.onTouchEnd = AFRAME.utils.bind(this.onTouchEnd, this);
    this.updateMapRotation = this.updateMapRotation.bind(this);
    this.onPointerLockChange = AFRAME.utils.bind(this.onPointerLockChange, this);
    this.onPointerLockError = AFRAME.utils.bind(this.onPointerLockError, this);
    this.onRotateCamera = AFRAME.utils.bind(this.onRotateCamera, this);
    this.onLookAtTarget = AFRAME.utils.bind(this.onLookAtTarget, this);
  },

  setupControls: function () {
    this.mouseDown = false;
    this.pitchObject = new THREE.Object3D();
    this.yawObject = new THREE.Object3D();
    this.yawObject.add(this.pitchObject);

    this.pitchObject.rotation.x = this.el.object3D.rotation.x;
    this.yawObject.rotation.y = this.el.object3D.rotation.y;
  },

  addEventListeners: function () {
    let sceneEl = this.el.sceneEl;
    let canvasEl = sceneEl.canvas;

    if (!canvasEl) {
      sceneEl.addEventListener('render-target-loaded', bind(this.addEventListeners, this));
      return;
    }

    // mouse events
    canvasEl.addEventListener('mousedown', this.onMouseDown, false);
    window.addEventListener('mousemove', this.onMouseMove, false);
    window.addEventListener('mouseup', this.onMouseUp, false);
    canvasEl.addEventListener('wheel', this.onMouseWheel, false);

    // touch events
    canvasEl.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchmove', this.onTouchMove);
    window.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('touchcancel', this.onTouchEnd);

    // pointer Lock events
    if (this.data.pointerLockEnabled) {
      document.addEventListener('pointerlockchange', this.onPointerLockChange, false);
      document.addEventListener('mozpointerlockchange', this.onPointerLockChange, false);
      document.addEventListener('pointerlockerror', this.onPointerLockError, false);
    }

    // custom events
    window.addEventListener('rotatecamera', this.onRotateCamera);
    window.addEventListener('cameralookat', this.onLookAtTarget);
  },

  removeEventListeners: function () {
    let sceneEl = this.el.sceneEl;
    let canvasEl = sceneEl && sceneEl.canvas;

    if (!canvasEl) {
      return;
    }

    // mouse events
    canvasEl.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    canvasEl.removeEventListener('wheel', this.onMouseWheel, false);

    // touch events
    canvasEl.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('touchcancel', this.onTouchEnd);

    // pointer Lock events
    document.removeEventListener('pointerlockchange', this.onPointerLockChange, false);
    document.removeEventListener('mozpointerlockchange', this.onPointerLockChange, false);
    document.removeEventListener('pointerlockerror', this.onPointerLockError, false);

    // custom events
    window.removeEventListener('rotatecamera', this.onRotateCamera);
    window.removeEventListener('cameralookat', this.onLookAtTarget);
  },

  // update object rotation from mouse/touch drag
  updateDragOrientation: function () {
    let object3D = this.el.object3D;
    let pitchObject = this.pitchObject;
    let yawObject = this.yawObject;

    object3D.rotation.x = pitchObject.rotation.x;
    object3D.rotation.y = yawObject.rotation.y;
    object3D.rotation.z = 0.0;


    this.updateMapRotation();
  },

  // update object rotation to smoothly rotate towards a target point
  updateLookAtOrientation(dt) {
    let object3D = this.el.object3D;
    let progress = this.lookAtProgress;
    let duration = this.data.lookAtDuration;

    let origin = this.lookAtOrigin;
    let target = this.lookAtTarget;

    progress = Math.min(progress + dt / duration, 1.0);
    let value = Math.sin(progress * Math.PI * 0.5);

    THREE.Quaternion.slerp(origin, target, object3D.quaternion, value);
    object3D.rotation.z = 0.0;

    this.pitchObject.rotation.x = this.el.object3D.rotation.x;
    this.yawObject.rotation.y = this.el.object3D.rotation.y;

    this.lookAtProgress = progress;

    if (progress > 0.999) {
      this.hasLookAtTarget = false;
    }


    this.updateMapRotation();
  },

  // update object rotation from external events
  updateExternalOrientation: function (dt) {
    let pitchObject = this.pitchObject;
    let yawObject = this.yawObject;
    let object3D = this.el.object3D;

    let sensitivity = this.data.externalSensitivity;
    let left = this.hasExternalRotation(this.constants.rotation.LEFT);
    let right = this.hasExternalRotation(this.constants.rotation.RIGHT);
    let up = this.hasExternalRotation(this.constants.rotation.UP);
    let down = this.hasExternalRotation(this.constants.rotation.DOWN);

    let maxSpeed = 2.5;
    let smoothTime = 0.025;

    // update horizontal rotation
    let data = this.externalRotationData.x;
    if (data.timer > epsilon) {
      data.timer = Math.max(0.0, data.timer - dt);
    }

    if (left && !right) {
      data.target = 1.0;
    } else if (right && !left) {
      data.target = -1.0;
    } else if (approximately(data.timer, 0.0)) {
      data.target = 0.0;
    }

    let result = smoothDamp(data.current, data.target, data.velocity, maxSpeed, smoothTime, dt);
    data.current = result.value;
    data.velocity = result.velocity;

    this.externalRotationData.x = data;

    // update horizontal rotation
    data = this.externalRotationData.y;
    if (data.timer > epsilon) {
      data.timer = Math.max(0.0, data.timer - dt);
    }

    if (up && !down) {
      data.target = 1.0;
    } else if (down && !up) {
      data.target = -1.0;
    } else if (approximately(data.timer, 0.0)) {
      data.target = 0.0;
    }

    result = smoothDamp(data.current, data.target, data.velocity, maxSpeed, smoothTime, dt);
    data.current = result.value;
    data.velocity = result.velocity;

    this.externalRotationData.y = data;

    // apply rotation to helper objects
    pitchObject.rotation.x += this.externalRotationData.y.current * dt * sensitivity;
    yawObject.rotation.y += this.externalRotationData.x.current * dt * sensitivity;

    // apply rotation to camera
    object3D.rotation.x = pitchObject.rotation.x;
    object3D.rotation.y = yawObject.rotation.y;

    this.updateMapRotation();
  },

  onMouseDown: function (evt) {
    let sceneEl = this.el.sceneEl;

    if (evt.button !== 0) {
      return;
    }

    let canvasEl = sceneEl && sceneEl.canvas;

    this.mouseDown = true;
    this.previousMouseEvent = evt;
    this.showGrabbingCursor();

    this.stopLookAt();
    this.stopExternalRotation();

    if (this.data.pointerLockEnabled && !this.pointerLocked) {
      if (canvasEl.requestPointerLock) {
        canvasEl.requestPointerLock();
      } else if (canvasEl.mozRequestPointerLock) {
        canvasEl.mozRequestPointerLock();
      }
    }
  },

  onMouseMove: function (evt) {
    let movementX;
    let movementY;
    let canvas = this.el.sceneEl.canvas;
    let direction = this.data.reverseMouseDrag ? 1 : -1;
    let pitchObject = this.pitchObject;
    let yawObject = this.yawObject;
    let previousMouseEvent = this.previousMouseEvent;
    let sensitivity = this.data.mouseSensitivity;
    let maxVerticalAngle = this.data.verticalAngle * THREE.MathUtils.DEG2RAD * 0.5;

    if (!this.data.enabled || (!this.mouseDown && !this.pointerLocked)) {
      return;
    }

    if (this.pointerLocked) {
      movementX = evt.movementX || evt.mozMovementX || 0;
      movementY = evt.movementY || evt.mozMovementY || 0;
    } else {
      movementX = (evt.screenX - previousMouseEvent.screenX) / canvas.clientWidth;
      movementY = (evt.screenY - previousMouseEvent.screenY) / canvas.clientHeight;
    }

    this.previousMouseEvent = evt;

    yawObject.rotation.y += movementX * sensitivity * direction;
    pitchObject.rotation.x += movementY * sensitivity * direction;
    pitchObject.rotation.x = clamp(pitchObject.rotation.x, -maxVerticalAngle, maxVerticalAngle);

    this.updateMapRotation();
  },

  onMouseUp: function () {
    this.mouseDown = false;
    this.hideGrabbingCursor();
  },

  onMouseWheel: function (event) {
    let sensitivity = this.data.zoomSensitivity;
    let min = this.data.minZoom;
    let max = this.data.maxZoom;

    let zoom = this.targetZoom;
    zoom += event.wheelDelta * sensitivity;
    zoom = clamp(zoom, min, max);

    this.targetZoom = zoom;
  },

  updateZoom: function (dt) {
    let camera = this.el.components.camera.camera;
    let current = camera.zoom;
    let target = this.targetZoom;

    if (approximately(current, target)) {
      return;
    }

    let velocity = this.zoomVelocity;
    let smoothTime = 0.25;
    let maxSpeed = this.data.zoomSpeed;

    let result = smoothDamp(current, target, velocity, maxSpeed, smoothTime, dt);

    this.zoomVelocity = result.velocity;
    camera.zoom = result.value;
    camera.updateProjectionMatrix();
  },

  onTouchStart: function (evt) {
    if (evt.touches.length !== 1) {
      return;
    }

    this.stopLookAt();
    this.stopExternalRotation();

    this.touchStarted = true;
    this.previousTouch = {
      pageX: evt.touches[0].pageX,
      pageY: evt.touches[0].pageY,
    };
  },

  onTouchMove: function (evt) {
    let movementX;
    let movementY;
    let canvas = this.el.sceneEl.canvas;
    let direction = this.data.reverseTouchDrag ? 1 : -1;
    let pitchObject = this.pitchObject;
    let yawObject = this.yawObject;
    let previousTouch = this.previousTouch;
    let sensitivity = this.data.touchSensitivity;
    let maxVerticalAngle = this.data.verticalAngle * THREE.MathUtils.DEG2RAD * 0.5;

    if (!this.data.enabled || !this.touchStarted) {
      return;
    }

    movementX = (evt.touches[0].pageX - previousTouch.pageX) / canvas.clientWidth;
    movementY = (evt.touches[0].pageY - previousTouch.pageY) / canvas.clientHeight;

    this.previousTouch = {
      pageX: evt.touches[0].pageX,
      pageY: evt.touches[0].pageY,
    };

    yawObject.rotation.y -= movementX * sensitivity * direction;
    pitchObject.rotation.x -= movementY * sensitivity * direction;
    pitchObject.rotation.x = clamp(pitchObject.rotation.x, -maxVerticalAngle, maxVerticalAngle);

    this.updateMapRotation();
  },

  updateMapRotation: function () {
    var event = new CustomEvent('geberit.rotateMap', { detail: { rotation: this.yawObject.rotation.y } });
    window.dispatchEvent(event);
  },

  onTouchEnd: function () {
    this.touchStarted = false;
  },

  onPointerLockChange: function () {
    this.pointerLocked = !!(document.pointerLockElement || document.mozPointerLockElement);
  },

  onPointerLockError: function () {
    this.pointerLocked = false;
  },

  exitPointerLock: function () {
    document.exitPointerLock();
    this.pointerLocked = false;
  },

  showGrabbingCursor: function () {
    this.el.sceneEl.canvas.style.cursor = 'grabbing';
  },

  hideGrabbingCursor: function () {
    this.el.sceneEl.canvas.style.cursor = '';
  },

  updateGrabCursor: function (enabled) {
    let sceneEl = this.el.sceneEl;

    function enableGrabCursor() {
      sceneEl.canvas.classList.add('a-grab-cursor');
    }
    function disableGrabCursor() {
      sceneEl.canvas.classList.remove('a-grab-cursor');
    }

    if (!sceneEl.canvas) {
      if (enabled) {
        sceneEl.addEventListener('render-target-loaded', enableGrabCursor);
      } else {
        sceneEl.addEventListener('render-target-loaded', disableGrabCursor);
      }
      return;
    }

    if (enabled) {
      enableGrabCursor();
    } else {
      disableGrabCursor();
    }
  },

  saveCameraPose: function () {
    let el = this.el;

    this.savedPose.position.copy(el.object3D.position);
    this.savedPose.rotation.copy(el.object3D.rotation);
    this.hasSavedPose = true;
  },

  restoreCameraPose: function () {
    let el = this.el;
    let savedPose = this.savedPose;

    if (!this.hasSavedPose) {
      return;
    }

    el.object3D.position.copy(savedPose.position);
    el.object3D.rotation.copy(savedPose.rotation);
    this.hasSavedPose = false;
  },

  // listens to a 'cameralookat' event on the window object
  onLookAtTarget: function (event) {
    this.lookAt(event.detail.target);
  },

  // triggers smooth rotation towards a specified target point
  lookAt: function (target) {
    if (this.mouseDown || this.touchStarted) {
      return;
    }

    if (this.externalRotation !== this.constants.rotation.NONE) {
      return;
    }

    if (target === undefined) {
      return;
    }

    if (target.x === undefined || target.y === undefined || target.z === undefined) {
      return;
    }

    let object3D = this.el.object3D;
    let originRotation = object3D.rotation.clone();

    this.hasLookAtTarget = true;
    this.lookAtProgress = 0.0;
    this.lookAtTarget = new THREE.Vector3(-target.x, -target.y, -target.z);

    // set original rotation
    this.lookAtOrigin = object3D.quaternion.clone();

    // rotate towards target
    object3D.lookAt(this.lookAtTarget);

    // set target rotation
    this.lookAtTarget = object3D.quaternion.clone();

    // reset object rotation
    object3D.quaternion.setFromEuler(originRotation);

    // stop rotation from external sources
    this.stopExternalRotation();
  },

  // stops look at rotation
  stopLookAt: function () {
    this.hasLookAtTarget = false;
    this.lookAtProgress = 0.0;
    this.lookAtOrigin = new THREE.Vector3();
    this.lookAtTarget = new THREE.Vector3();
  },

  hasExternalRotation(direction) {
    return (this.externalRotation & direction) > 0;
  },

  // listens to a 'rotatecamera' event on the window object
  onRotateCamera: function (event) {
    if (this.mouseDown || this.touchStarted) {
      return;
    }

    let active = event.detail.active;
    let direction = event.detail.direction;

    if (active) {
      this.stopLookAt();
      this.externalRotation |= direction;

      if (
        this.hasExternalRotation(this.constants.rotation.LEFT) ||
        this.hasExternalRotation(this.constants.rotation.RIGHT)
      ) {
        this.externalRotationData.x.timer = 0.5;
      }
      if (
        this.hasExternalRotation(this.constants.rotation.UP) ||
        this.hasExternalRotation(this.constants.rotation.DOWN)
      ) {
        this.externalRotationData.y.timer = 0.5;
      }
    } else {
      this.externalRotation &= ~direction;
    }
  },

  // stops rotation from external sources
  stopExternalRotation: function () {
    this.externalRotation = this.constants.rotation.NONE;

    this.externalRotationData.x.current = 0.0;
    this.externalRotationData.x.target = 0.0;
    this.externalRotationData.x.velocity = 0.0;
    this.externalRotationData.x.timer = 0.0;

    this.externalRotationData.y.current = 0.0;
    this.externalRotationData.y.target = 0.0;
    this.externalRotationData.y.velocity = 0.0;
    this.externalRotationData.y.timer = 0.0;
  },
});
