
const epsilon = 0.0001;

function approximately(a, b) {
    return Math.abs(a - b) < epsilon;
}

function clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
}

function smoothDamp(current, target, velocity, maxSpeed, smoothTime, deltaTime) {
    let max = maxSpeed * smoothTime;

    let v1 = 2.0 / smoothTime;
    let v2 = v1 * deltaTime;
    let v3 = (1.0 / (1.0 + v2 + 0.4799 * v2 * v2 + 0.2349 * v2 * v2 * v2));
    let r1 = target;

    let v4 = Math.min(max, Math.max(-max, current - target));
    let v5 = (velocity + v1 * v4) * deltaTime;
    let r2 = (current - v4) + (v4 + v5) * v3;

    let result = {
        value: 0,
        velocity: 0
    };

    if ((r1 - current > 0.0) === (r2 > r1)) {
        result.value = r1;
        result.velocity = (r2 - r1) / deltaTime;
    } else {
        result.value = r2;
        result.velocity = (velocity - v1 * v5) * v3;
    }

    return result;
}

export { epsilon, approximately, clamp, smoothDamp };