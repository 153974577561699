import { getGeometry, getMaterial, getTexture } from './asset-cache.js';
import { isEmpty } from '../../utils/helper.js';
import { trackButtonEvent } from '../../utils/analytics';

AFRAME.registerComponent('hotspot', {
    schema: {
        content: { default: undefined },
        hoverDuration: { default: 0.2 },
        clickDuration: { default: 0.25 },
        size: { default: 0.4 }
    },

    init: function () {
        this.baseScale = 1.3;
        this.bounceAnimation = {
            progress: Math.random() * 5.0,
            value: 0.0,
            scale: 0.4,
            delay: 5.0,
            duration: 0.75
        };

        this.isHovered = false;
        this.hoverAnimation = {
            progress: 0.0,
            value: 0.0,
            scale: 0.25,
            bounceFactor: 0.2,
            bounceValue: 1.0
        };

        this.isSelected = false;
        this.clickAnimation = {
            progress: 0.0,
            value: 0.0,
            rotation: 135.0
        };

        // element references
        let el = this.el;

        // create a rotation pivot
        this.pivotEl = document.createElement('a-entity');
        el.appendChild(this.pivotEl);

        // create a content container
        let contentEl = document.createElement('a-entity');
        this.pivotEl.appendChild(contentEl);

        // load and setup textures
        let mainTexture = getTexture('hs-text-main', 'src/assets/images/sprites/hotspot-main.png');
        let backgroundTexture = getTexture('hs-text-back', 'src/assets/images/sprites/hotspot-background.png');
        let iconTexture = getTexture('hs-tex-icon', 'src/assets/images/sprites/hotspot-plus.png');

        let size = this.data.size;

        let geometry = getGeometry('hs-geo-all', 'plane', { width: size, height: size });

        // create background mesh
        let backgroundMaterial = getMaterial('hs-mat-back', { map: backgroundTexture, side: THREE.DoubleSide, transparent: true, depthWrite: false });
        let backgroundMesh = new THREE.Mesh(geometry, backgroundMaterial);
        backgroundMesh.scale.set(this.baseScale, this.baseScale, this.baseScale);
        backgroundMesh.position.set(0, 0, -0.1);
        contentEl.setObject3D('background', backgroundMesh);

        // create circle mesh
        let circleMaterial = getMaterial('hs-mat-main', { map: mainTexture, side: THREE.DoubleSide, transparent: true, depthWrite: false });
        let circleMesh = new THREE.Mesh(geometry, circleMaterial);
        contentEl.setObject3D('circle', circleMesh);

        // create icon mesh
        let iconMaterial = getMaterial('hs-mat-icon', { map: iconTexture, side: THREE.DoubleSide, transparent: true, depthWrite: false });
        let iconMesh = new THREE.Mesh(geometry, iconMaterial);
        iconMesh.position.set(0, 0, 0.1);
        contentEl.setObject3D('icon', iconMesh);

        // create a invisble hitbox for hover and click interactions
        let hitbox = document.createElement('a-entity');
        hitbox.setAttribute('id', 'clickable');
        hitbox.setAttribute("geometry", { primitive: 'sphere', radius: size * 1.25 });
        hitbox.setAttribute("material", { opacity: 0.0, transparent: true, depthWrite: false });
        this.pivotEl.appendChild(hitbox);

        // create the info box
        let infobox = document.createElement('a-entity');
        infobox.setAttribute('id', 'info-box');
        infobox.setAttribute('info-box', { content: this.data.content, parent: this });
        this.el.appendChild(infobox);

        this.bindMethods();
        this.addEventListener();
    },

    update: function () {
        if (this.data.content !== undefined) {
            let infobox = this.el.querySelector('#info-box');
            infobox.setAttribute('info-box', { content: this.data.content });
        }
    },

    remove: function () {
        this.removeEventListener();
    },

    bindMethods: function () {
        this.onMouseEnter = AFRAME.utils.bind(this.onMouseEnter, this);
        this.onMouseLeave = AFRAME.utils.bind(this.onMouseLeave, this);
        this.onClick = AFRAME.utils.bind(this.onClick, this);
        this.onInfoBoxClosed = AFRAME.utils.bind(this.onInfoBoxClosed, this);
        this.onViewpointLoaded = AFRAME.utils.bind(this.onViewpointLoaded, this);
    },

    addEventListener: function () {
        let hitboxEl = this.el.querySelector('#clickable');
        let infobox = this.el.querySelector('#info-box');

        hitboxEl.addEventListener('mouseenter', this.onMouseEnter);
        hitboxEl.addEventListener('mouseleave', this.onMouseLeave);
        hitboxEl.addEventListener('click', this.onClick);
        infobox.addEventListener('closed', this.onInfoBoxClosed);
    },

    removeEventListener() {
        let hitboxEl = this.el.querySelector('#clickable');
        let infobox = this.el.querySelector('#info-box');

        hitboxEl.removeEventListener('mouseenter', this.onMouseEnter);
        hitboxEl.removeEventListener('mouseleave', this.onMouseLeave);
        hitboxEl.removeEventListener('click', this.onClick);
        infobox.removeEventListener('closed', this.onInfoBoxClosed);
    },

    tick: function (time, timeDelta) {
        // convert from ms to s
        let dt = timeDelta / 1000.0;

        // update hotspot
        this.updateLookAt();
        this.updateHover(dt);
        this.updateClick(dt);
        this.updateBounce(dt);
        this.applyAnimation();
    },

    updateLookAt: function () {
        // get a reference to the camera and rotate towards camera
        let camera = document.querySelector('[camera]').getObject3D('camera');
        this.pivotEl.object3D.lookAt(camera.position);
    },

    updateHover: function (dt) {
        let isHovered = this.isHovered;
        let progress = this.hoverAnimation.progress;

        // abort if animation is done
        if (isHovered && progress > 0.999) {
            return false;
        }

        if (!isHovered && progress < 0.001) {
            return false;
        }

        let duration = this.data.hoverDuration;
        let scale = this.hoverAnimation.scale;
        let bounceFactor = this.hoverAnimation.bounceFactor;

        if (isHovered) {
            progress = Math.min(progress + dt / duration, 1.0);
        } else {
            progress = Math.max(progress - dt / duration, 0.0);
        }

        let value = Math.sin(progress * Math.PI * 0.5);
        this.hoverAnimation.value = scale * value;
        this.hoverAnimation.bounceValue = bounceFactor + (1.0 - bounceFactor) * (1.0 - value);
        this.hoverAnimation.progress = progress;

        return true;
    },

    updateClick: function (dt) {
        let isSelected = this.isSelected;
        let progress = this.clickAnimation.progress;

        // abort if animation is done
        if (isSelected && progress > 0.999) {
            return false;
        }

        if (!isSelected && progress < 0.001) {
            return false;
        }

        let duration = this.data.clickDuration;
        let rotation = this.clickAnimation.rotation;

        if (isSelected) {
            progress = Math.min(progress + dt / duration, 1.0);
        } else {
            progress = Math.max(progress - dt / duration, 0.0);
        }

        this.clickAnimation.progress = progress;

        let value = 1.0 - (Math.cos(progress * Math.PI) * 0.5 + 0.5);
        this.clickAnimation.value = rotation * value * THREE.MathUtils.DEG2RAD;

        return true;
    },

    updateBounce: function (dt) {
        let progress = this.bounceAnimation.progress;
        let delay = this.bounceAnimation.delay;

        progress += dt;
        if (progress >= delay) {
            let scale = this.bounceAnimation.scale;
            let hoverFactor = this.hoverAnimation.bounceValue;
            let duration = this.bounceAnimation.duration;
            let value = Math.min((progress - delay) / duration, 1.0);

            this.bounceAnimation.value = scale * hoverFactor * Math.sin(value * Math.PI);

            if (value > 0.999) {
                progress = 0.0;
                this.bounceAnimation.value = 0.0;
            }
        }

        this.bounceAnimation.progress = progress;
    },

    applyAnimation: function () {
        let pivotEl = this.el.querySelector('a-entity');
        let contentEl = pivotEl.querySelector('a-entity');
        let background = contentEl.getObject3D('background');
        let icon = contentEl.getObject3D('icon');

        // animate background
        let baseScale = this.baseScale;
        let hoverScale = this.hoverAnimation.value;
        let bounceScale = this.bounceAnimation.value;
        let scale = baseScale + hoverScale + bounceScale;
        background.scale.set(scale, scale, scale);

        // animate icon
        let rotation = this.clickAnimation.value;
        icon.rotation.set(0.0, 0.0, rotation);
    },

    onMouseEnter: function () {
        // object is now hovered
        this.isHovered = true;
        this.hoverAnimation.progress = 0.0;
    },

    onMouseLeave: function () {
        // object is no longer hovered
        this.isHovered = false;
    },

    onClick: function () {
        let targetViewpoints = this.data.content['target-viewpoints'];
        if (this.isSelected || isEmpty(targetViewpoints)) {
            this.setSelected(!this.isSelected);
        } else {
            let currentViewpoint = document.querySelector('a-scene').components['scene-loader'].viewpointId;
            if (!targetViewpoints.includes(currentViewpoint)) {
                let targetViewpoint = targetViewpoints[0];
                let viewpoint = document.querySelector('#' + currentViewpoint + '_' + targetViewpoint);
                let targetPosition = !isEmpty(viewpoint) ? viewpoint.object3D.position : undefined;

                var event = new CustomEvent('loadviewpoint', { detail: { viewpointId: targetViewpoint, viewpointPosition: targetPosition } });
                window.dispatchEvent(event);

                window.addEventListener('viewpointloaded', this.onViewpointLoaded);
            } else {
                this.setSelected(!this.isSelected);
            }
        }
    },

    onViewpointLoaded: function () {
        setTimeout(() => {
            let sceneLoader = document.querySelector('a-scene').components['scene-loader'];
            let hotspot = document.querySelector('#' + sceneLoader.viewpointId + '_' + this.data.content.id).components.hotspot;
            if (!isEmpty(hotspot)) {
                hotspot.setSelected(true);
            }
        }, 500);

        window.removeEventListener('viewpointloaded', this.onViewpointLoaded);
    },

    setSelected(selected) {
        if (this.isSelected !== selected) {
            this.isSelected = selected;

            let infobox = this.el.querySelector('#info-box').components['info-box'];
            if (this.isSelected) {
                infobox.open();
                trackButtonEvent(`Hotspot: ${this.data.content.id}`);
            } else {
                infobox.close();
            }
        }
    },

    onInfoBoxClosed: function () {
        this.setSelected(false);
    }
});