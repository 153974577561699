import { getGeometry, getMaterial, getTexture } from './asset-cache.js';

AFRAME.registerComponent('viewpoint-mark', {
  schema: {
    viewpoint: { default: '' },
    width: { default: 1 },
    height: { default: 1 },
    baseColor: { default: '#ffffff' },
    glowColor: { default: '#c9e9ff' },
    rotationSpeed: { default: 45.0 },
    clickDuration: { default: 0.45 },
    hoverDuration: { default: 0.3 }
  },

  init: function () {
    // constants for animation
    this.MAX_POSITION_Y = 0.05;
    this.MAX_OUTER_HOVER_OPACITY = 0.45;
    this.MAX_INNER_HOVER_OPACITY = 0.2;
    this.MAX_OUTER_ClICK_OPACITY = 0.6;
    this.MAX_INNER_CLICK_OPACITY = 0.3;
    this.MAX_SCALE_OFFSET = -0.1;

    // initialize input position
    this.inputPosition = new THREE.Vector2(0.0, 0.0);

    // hovered state and animation parameters
    this.isHovered = false;
    this.hoverAnimation = {
      progress: 0.0,
      outerOpacity: 0.0,
      innerOpacity: 0.0,
      positionY: this.MAX_POSITION_Y
    };

    // clicked state and animation parameters
    this.isClicked = false;
    this.clickAnimation = {
      progress: 0.0,
      outerOpacity: 0.0,
      innerOpacity: 0.0,
      positionY: this.MAX_POSITION_Y,
      scale: 1.0
    };

    // variables from component parameters
    let { width, height, baseColor, glowColor } = this.data;
    let halfWidth = width * 0.5;
    let halfHeight = height * 0.5;

    // element references
    let el = this.el;

    // load and setup textures
    let spriteTexture = getTexture('vm-tex-circle', 'src/assets/images/sprites/viewpoint.png');
    let cylinderTexture = getTexture('vm-tex-glow', 'src/assets/images/sprites/gradient.png');

    // calculate the angle to make the circle image point away from the camera
    let angle = 0.0;
    let camera = document.querySelector('[camera]').getObject3D('camera');
    if (camera !== undefined) {
      let objectPosition = this.el.object3D.position.clone();
      let cameraPosition = camera.position.clone();

      let direction = objectPosition.sub(cameraPosition);
      direction.normalize();

      angle = Math.atan2(direction.z, direction.x) - Math.PI * 0.5;
    }

    // create circle mesh
    let circleGeometry = getGeometry('vm-geo-circle', 'plane', { width: width, height: width });
    let circleMaterial = getMaterial('vm-mat-circle', { map: spriteTexture, color: baseColor, side: THREE.DoubleSide, transparent: true, depthWrite: false });
    let circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
    circleMesh.rotation.set(Math.PI * 0.5, 0, angle);
    circleMesh.position.set(0, this.MAX_POSITION_Y, 0);
    el.setObject3D('circle', circleMesh);

    // create glow mesh
    // for the visible effect, the glow consists of 2 meshes - one with backface culling and one with frontface culling
    let glowGeometry = getGeometry('vm-geo-glow', 'cylinder', { radius: halfWidth, height: height, open: true });

    let glowOuterMaterial = new THREE.MeshBasicMaterial({ map: cylinderTexture, color: glowColor, opacity: 0.0, side: THREE.FrontSide, transparent: true, depthWrite: false });
    let glowOuterMesh = new THREE.Mesh(glowGeometry, glowOuterMaterial);
    glowOuterMesh.position.set(0, halfHeight, 0);
    el.setObject3D('glow-outer', glowOuterMesh);

    let glowInnerMaterial = new THREE.MeshBasicMaterial({ map: cylinderTexture, color: glowColor, opacity: 0.0, side: THREE.DoubleSide, transparent: true, depthWrite: false });
    let glowInnerMesh = new THREE.Mesh(glowGeometry, glowInnerMaterial);
    glowInnerMesh.position.set(0, halfHeight, 0);
    el.setObject3D('glow-inner', glowInnerMesh);

    // create a invisble hitbox for hover and click interactions
    var hitbox = document.createElement('a-entity');
    hitbox.setAttribute('id', 'clickable');
    hitbox.setAttribute("geometry", { primitive: 'cylinder', radius: halfWidth * 1.8, height: 0.8 });
    hitbox.setAttribute("position", "0 0.1 0");
    hitbox.setAttribute("material", { opacity: 0.0, transparent: true, depthWrite: false });
    el.appendChild(hitbox);

    this.eventListenerAttached = false;

    this.bindMethods();

    const scene = document.querySelector('a-scene');
    this.addEventListener(scene);
  },

  remove: function () {
    const scene = document.querySelector('a-scene');
    this.removeEventListener(scene);
  },

  bindMethods() {
    // bind methods
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onToucheMove = this.onToucheMove.bind(this);

    this.onSceneDestroy = this.onSceneDestroy.bind(this);
  },

  addEventListener(scene) {
    if (this.eventListenerAttached) {
      return;
    }
    this.eventListenerAttached = true;

    // add event listener
    const hitbox = this.el.querySelector('#clickable');
    hitbox.addEventListener('mouseenter', this.onMouseEnter);
    hitbox.addEventListener('mouseleave', this.onMouseLeave);
    hitbox.addEventListener('click', this.onClick);

    scene.addEventListener('mousedown', this.onMouseDown);
    scene.addEventListener('mousemove', this.onMouseMove);
    scene.addEventListener('touchstart', this.onTouchStart);
    scene.addEventListener('touchmove', this.onToucheMove);

    window.addEventListener('onscenedestroy', this.onSceneDestroy);
  },

  removeEventListener(scene) {
    if (!this.eventListenerAttached) {
      return;
    }
    this.eventListenerAttached = false;

    // remove event listener
    const hitbox = this.el.querySelector('#clickable');
    hitbox.removeEventListener('mouseenter', this.onMouseEnter);
    hitbox.removeEventListener('mouseleave', this.onMouseLeave);
    hitbox.removeEventListener('click', this.onClick);

    window.removeEventListener('onscenedestroy', this.onSceneDestroy);

    scene.removeEventListener('mousedown', this.onMouseDown);
    scene.removeEventListener('mousemove', this.onMouseMove);
    scene.removeEventListener('touchstart', this.onTouchStart);
    scene.removeEventListener('touchmove', this.onToucheMove);
  },

  onSceneDestroy(event) {
    const { scene } = event.detail;
    this.removeEventListener(scene);
  },

  tick: function (time, timeDelta) {
    // convert time delta from ms to s
    let dt = timeDelta / 1000.0;

    // the glow is rotated around the y axis, when the mark is hovered
    if (this.isHovered) {
      this.updateRotation(dt);
    }

    // update parameters for hover and click animation
    let changed = false;
    changed |= this.updateHover(dt);
    changed |= this.updateClick(dt);

    // apply animation values to geometry and materials
    if (changed) {
      this.applyAnimation();
    }
  },

  updateRotation: function (dt) {
    // get rotation speed from component parameters
    let rotationSpeed = this.data.rotationSpeed;

    // setup helper variable to convert degree to radians
    let d2r = THREE.MathUtils.DEG2RAD;

    // rotate glow geometry
    let glowOuter = this.el.getObject3D('glow-outer');
    glowOuter.rotateY(rotationSpeed * 0.5 * d2r * dt);

    let glowInner = this.el.getObject3D('glow-inner');
    glowInner.rotateY(-rotationSpeed * d2r * dt);
  },

  updateHover: function (dt) {
    let isHovered = this.isHovered;
    let progress = this.hoverAnimation.progress;

    // abort if animation is done
    if (isHovered && progress > 0.999) {
      return false;
    }

    if (!isHovered && progress < 0.001) {
      return false;
    }

    // update progress with a damped cosine wave
    let sign = isHovered ? 1.0 : -1.0;
    let duration = this.data.hoverDuration;
    progress = Math.min(1.0, Math.max(0.0, progress + (dt / duration) * sign));
    let value = 1.0 - (Math.cos(progress * Math.PI) * 0.5 + 0.5);

    // update animation parameters
    this.hoverAnimation.outerOpacity = this.MAX_OUTER_HOVER_OPACITY * value;
    this.hoverAnimation.innerOpacity = this.MAX_INNER_HOVER_OPACITY * value;
    this.hoverAnimation.positionY = this.MAX_POSITION_Y * (1.0 - value);

    this.hoverAnimation.progress = progress;

    return true;
  },

  updateClick: function (dt) {
    let isClicked = this.isClicked;

    // abort if animation is done
    if (!isClicked) {
      return false;
    }

    // update progress with a damped sine wave
    let duration = this.data.clickDuration;
    let progress = this.clickAnimation.progress;
    progress = Math.min(1.0, Math.max(0.0, progress + (dt / duration)));
    let value = Math.sin(progress * Math.PI);

    // update animation parameters
    this.clickAnimation.outerOpacity = this.MAX_OUTER_ClICK_OPACITY * value;
    this.clickAnimation.innerOpacity = this.MAX_INNER_CLICK_OPACITY * value;
    this.clickAnimation.positionY = this.MAX_POSITION_Y * (1.0 - value);
    this.clickAnimation.scale = 1.0 + this.MAX_SCALE_OFFSET * value;

    this.clickAnimation.progress = progress;

    // the animation is done when the progress reaches 1.0
    if (progress > 0.999) {
      this.isClicked = false;
    }

    return true;
  },

  applyAnimation: function () {
    // get references to objects
    let glowOuter = this.el.getObject3D('glow-outer');
    let glowInner = this.el.getObject3D('glow-inner');
    let circle = this.el.getObject3D('circle');

    // calculate target values
    let outerOpacity = Math.max(this.clickAnimation.outerOpacity, this.hoverAnimation.outerOpacity);
    let innerOpacity = Math.max(this.clickAnimation.innerOpacity, this.hoverAnimation.innerOpacity);
    let positionY = Math.min(this.clickAnimation.positionY, this.hoverAnimation.positionY);
    let scale = this.clickAnimation.scale;

    // assign values to geometry and materials
    glowOuter.material.opacity = outerOpacity;
    glowInner.material.opacity = innerOpacity;
    circle.position.y = positionY;
    circle.scale.set(scale, scale, scale);
  },

  onMouseEnter: function () {
    // object is now hovered
    this.isHovered = true;
  },

  onMouseLeave: function () {
    // object is no longer hovered
    this.isHovered = false;
  },

  onClick: function () {
    if (this.inputDistance > 10.0) {
      return;
    }

    // object is clicked
    this.isClicked = true;
    this.clickAnimation.progress = 0.0;

    let viewpoint = this.data.viewpoint;
    let position = this.el.object3D.position;

    var event = new CustomEvent('loadviewpoint', { detail: { viewpointId: viewpoint, viewpointPosition: position } });
    window.dispatchEvent(event);
  },

  onMouseDown(event) {
    if (!this.isValidMouseEvent(event)) {
      return;
    }

    this.inputPosition = new THREE.Vector2(event.pageX, event.pageY);
    this.inputDistance = 0.0;
  },

  onMouseMove(event) {
    if (!this.isValidMouseEvent(event)) {
      return;
    }

    const inputPosition = new THREE.Vector2(event.pageX, event.pageY);
    this.inputDistance += this.inputPosition.distanceToSquared(inputPosition);

    this.inputPosition = inputPosition;
  },

  isValidMouseEvent(event) {
    if (!event.pageX || !event.pageY) {
      return false;
    }

    return true;
  },

  onTouchStart(event) {
    if (!this.isValidTouchEvent(event)) {
      return;
    }

    this.inputPosition = new THREE.Vector2(event.touches[0].pageX, event.touches[0].pageY);
    this.inputDistance = 0.0;
  },

  onToucheMove(event) {
    if (!this.isValidTouchEvent(event)) {
      return;
    }

    const inputPosition = new THREE.Vector2(event.touches[0].pageX, event.touches[0].pageY);
    this.inputDistance += this.inputPosition.distanceToSquared(inputPosition);

    this.inputPosition = inputPosition;
  },

  isValidTouchEvent(event) {
    if (!event.touches) {
      return false;
    }

    if (event.type === 'touchend') {
      if (event.touches.length !== 0) {
        return false;
      }
    } else {
      if (event.touches.length !== 1) {
        return false;
      }

      if (!event.touches[0].pageX || !event.touches[0].pageY) {
        return false;
      }
    }

    return true;
  }
});