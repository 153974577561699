function isEmpty(val) {
  if (val === undefined) {
    return true;
  }

  if (typeof (val) == 'function' || typeof (val) == 'number' || typeof (val) == 'boolean'
    || Object.prototype.toString.call(val) === '[object Date]') {
    return false;
  }


  if (val == null || val.length === 0) {
    return true;
  }

  if (typeof (val) == 'object') {
    var r = true;
    for (var f in val) {
      r = false;
    }
    return r;
  }
  return false;
}

function isIOS() {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return isIOS;
}

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function isMobile() {
  // $gb_breakpointMD: calc-pixel(992) !default;
  return window.innerWidth <= 992;
}

export { isEmpty, isIOS, isMobile, debounce };