let virtualPageviewStack = [];
let virtualPageviewBaseUrl = '';

function trimSlashes(value) {
  let result = value;
  if (result.startsWith('/')) {
    result = result.slice(1, result.length);
  }
  if (result.endsWith('/')) {
    result = result.slice(0, result.length - 1);
  }
  return result;
}

function dataLayerPush(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}

function trackVirtualPageview() {
  // construct url from base url and the path in the stack
  let url = '/';
  if (virtualPageviewBaseUrl !== undefined && virtualPageviewBaseUrl !== '') {
    url += `${virtualPageviewBaseUrl}/`;
  }

  for (let i = 0; i < virtualPageviewStack.length; i += 1) {
    url += virtualPageviewStack[i].url;
    if (i < virtualPageviewStack.length - 1) {
      url += '/';
    }
  }

  // get last item from stack as page title
  const lastIndex = virtualPageviewStack.length - 1;
  const { pageTitle } = virtualPageviewStack[lastIndex];

  // construct and push data
  const data = {
    event: 'virtualPageview',
    url,
    pageTitle,
  };
  dataLayerPush(data);
}

export function setVirtualPageviewBaseUrl(url) {
  // assign base url
  virtualPageviewBaseUrl = trimSlashes(url);
}

export function pushVirtualPageview(url, pageTitle) {
  // push data to stack
  virtualPageviewStack.push({
    url: trimSlashes(url),
    pageTitle,
  });

  // track page view
  trackVirtualPageview();
}

export function popVirtualPageview() {
  if (virtualPageviewStack.length > 0) {
    // pop data from stack
    virtualPageviewStack.pop();
    // track page view
    trackVirtualPageview();
  }
}

export function setVirtualPageview(url, pageTitle) {
  // clear stack and push page view
  virtualPageviewStack = [];
  pushVirtualPageview(url, pageTitle);
}

export function trackButtonEvent(label) {
  const data = {
    event: 'genericEvent',
    eventCategory: 'Buttons',
    eventAction: 'Click',
    eventLabel: label,
  };
  dataLayerPush(data);
}

export function trackCustomEvent(data) {
  dataLayerPush(data);
}
