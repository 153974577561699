import config from "./config";
import { Router } from '@vaadin/router';

let router = undefined;
 
window.onload = () => {
    const outlet = document.getElementById('root');
    router = new Router(outlet);
    router.setRoutes([
        {path: '/', component: 'gb-provider'},
        {path: `/(${config.routes})`, component: 'gb-provider'},
    ]);
};

export default router;