import { isEmpty } from '../utils/helper';
import { trackButtonEvent } from '../utils/analytics';

class GbHeader extends HTMLElement {
  constructor() {
    super();
    this.handleButtonClick = this.handleCTAClick.bind(this);
    this.render = this.render.bind(this);
    this._content = {};
    this.afterConnectedCallback = false;
  }

  set content(value) {
    this._content = value;
    this.afterConnectedCallback && this.render();
  }

  handleCTAClick() {
    trackButtonEvent('CONTACT GEBERIT CONSULTANT');
  }

  get content() {
    return this._content;
  }

  render() {
    if (isEmpty(this._content)) {
      return;
    }
    const { logo, ['contact-form']: contactform, languageSwitch } = this._content;
    this.innerHTML = '';
    let html = `
                    <header id="page-header" class="gb-header">
                        <div class="gb-header__container">
                            <div class="gb-header__brand">
                                <a id="logo" class="gb-brand gb-brand__logo" href="${logo.url}" target="_blank">
                                    <img class="gb-brand__image" 
                                        src="src/assets/images/geberit_logo.svg" 
                                        alt="GEBERIT" 
                                        title="GEBERIT">
                                    <span class="gb-brand__claim">${logo.label}</span>
                                </a>
                            </div>
                            
                            <div class="gb-header__language-switch">
                                <div class="${languageSwitch.showLanguageSwitch}">
                                    <span class="selected-language-abbr">${languageSwitch.selectedLanguage}</span>
                                    <ul>
                                        <li class="selected-language"><strong>${languageSwitch.selectedLanguageLong}</strong></li>
                                        <li class="change-language"><a href="${languageSwitch.changeLanguageUrl1}">${languageSwitch.changeLanguageLong1}</a></li>
                                        <li class="change-language"><a href="${languageSwitch.changeLanguageUrl2}">${languageSwitch.changeLanguageLong2}</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="gb-header__nav">
                                <a href="${contactform.url}" class="button" target="_blank">
                                    <i class="web20-icon web20-icon-arrow-link-light-right"></i>
                                    ${contactform.label}
                                </a>
                            </div>
                        </div>
                    </header>`;
    this.insertAdjacentHTML('afterbegin', html);
  }

  connectedCallback() {
    this.render();
    this.afterConnectedCallback = true;

    let cta = this.querySelector('.button');
    cta.addEventListener('click', this.handleCTAClick);
  }
}

customElements.define('gb-header', GbHeader);
