import { isEmpty } from '../utils/helper';
import { popVirtualPageview } from '../utils/analytics';

class GbTutorial extends HTMLElement {
  constructor() {
    super();
    this.renderBoxContent = this.renderBoxContent.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.render = this.render.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.clearHTML = this.clearHTML.bind(this);
    this._content = {};
    this.afterConnectedCallback = false;
  }

  set content(value) {
    this._content = value;
    this.afterConnectedCallback && this.render();
  }

  get content() {
    return this._content;
  }

  connectedCallback() {
    this.className = 'gb-tutorial gb-tutorial--hidden';
    this.render();
    this.afterConnectedCallback = true;
  }

  disconnectedCallback() {
    let btn = this.querySelector('button');
    btn.removeEventListener('click', this.handleClick);
  }

  handleClick() {
    this.classList.add('gb-tutorial--hidden');
    popVirtualPageview();
  }

  render() {
    if (isEmpty(this._content)) {
      return;
    }
    this.clearHTML();
    const { title } = this._content;
    let html = `
                        <gb-backdrop>
                            <div class="gb-tutorial__wrapper">
                                <div class="gb-tutorial__content">
                                    <h2>${title}</h2>
                                    ${this.renderBoxContent()}
                                    ${this.renderButton()}
                                </div>
                            </div>
                        </gb-backdrop>
                        `;
    this.insertAdjacentHTML('afterbegin', html);
    let btn = this.querySelector('button');
    btn.addEventListener('click', this.handleClick);
  }

  clearHTML() {
    this.removeListener();
    this.innerHTML = '';
  }

  removeListener() {
    let btn = this.querySelector('button');
    !isEmpty(btn) && btn.removeEventListener('click', this.handleClick);
  }

  renderButton() {
    const { 'btn-label': btnLabel } = this._content;
    let btn = document.createElement('button');
    btn.className = 'button button--transparent';
    btn.innerHTML = btnLabel;
    return btn.outerHTML;
  }

  renderBoxContent() {
    const { tutorial } = this._content;
    let div = document.createElement('div');
    div.classList.add('gb-tutorial__box-container');
    tutorial.forEach((elem, index) => {
      const { img1, img2 } = elem;
      div.innerHTML += `<gb-box>
                                <div>
                                    <img src='${img1.url}' alt='${img1.alt}' class="info-img-${index}">
                                 </div>
                                 <div>
                                    <img src='${img2.url}' alt='${img2.alt}'>
                                </div>
                                <p>${elem.text}</p>
                             </gb-box>`;
    });
    return div.outerHTML;
  }
}

customElements.define('gb-tutorial', GbTutorial);
