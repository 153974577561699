import { THREE } from 'aframe';
import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader';


let geometryMap = new Map();
let materialMap = new Map();
let textureMap = new Map();
let textureLoadMap = new Map();
let textureLoader = new THREE.TextureLoader();
let basisTextureLoader = new BasisTextureLoader();

let checkedBasisSupport = false;

function init() {
  basisTextureLoader.setTranscoderPath('./libs/');
}

function initializeCache() {
    THREE.Cache.enabled = false;
}

function getGeometry(key, type, data) {
    if(geometryMap.has(key)) {
        return geometryMap.get(key);
    } else {
        let geometry = null;
        switch(type) {
            case 'plane':
                geometry = new THREE.PlaneGeometry(data.width, data.height);
                break;
            case 'cylinder':
                geometry = new THREE.CylinderGeometry(data.radius, data.radius, data.height, 32, 1, data.open);
                break;
            }

        if(geometry !== null) {
            geometryMap.set(key, geometry);
        }

        return geometry;
    }
}

function hasGeometry(key) {
    return geometryMap.has(key);
}

function disposeGeometry(key) {
    if(geometryMap.has(key)) {
        let geometry = geometryMap.get(key);
        geometryMap.delete(key);
        geometry.dispose();
    }
}

function getMaterial(key, data) {
    if(materialMap.has(key)) {
        return materialMap.get(key);
    } else {
        let material = new THREE.MeshBasicMaterial(data);
        materialMap.set(key, material);
        return material;
    }
}

function hasMaterial(key) {
    return materialMap.has(key);
}

function disposeMaterial(key) {
    if(materialMap.has(key)) {
        let material = materialMap.get(key);
        materialMap.delete(key);
        material.dispose();
    }
}

function getTextureLoader(path) {
    if (path.endsWith('.basis')) {
      return basisTextureLoader;
    }
    return textureLoader;
  }

function getTexture(key, path, data, onSuccess, onError) {
    if (!checkedBasisSupport) {
        const webGlRenderer = document.querySelector('a-scene').renderer;
        basisTextureLoader.detectSupport(webGlRenderer);

        // workaround for windows: cTFBC7_M5 format causes an invalid type error in Chrome and Firefox
        // if (basisTextureLoader.workerConfig.format === BasisTextureLoader.BASIS_FORMAT.cTFBC7_M5) {
        //     basisTextureLoader.workerConfig.format = BasisTextureLoader.BASIS_FORMAT.cTFBC3;
        // }

        checkedBasisSupport = true;
    }

    if(textureMap.has(key)) {
        let texture = textureMap.get(key);
        if(isLoadingTexture(key)) {
            addTextureLoadedCallback(key, onSuccess, onError);
        } else {
            if(onSuccess) {
                onSuccess(texture);
            }
        }
        return texture;
    } else {
        addTextureLoadedCallback(key, onSuccess, onError);
        
        const loader = getTextureLoader(path);
        let texture = loader.load(
            path,
            onLoadTextureSuccess.bind(this, key, data),
            null,
            onLoadTextureError.bind(this, key)
        );

        return texture;
    }
}

function isLoadingTexture(key) {
    return textureLoadMap.has(key);
}

function addTextureLoadedCallback(key, onSuccess, onError) {
    if(!textureLoadMap.has(key)) {
        textureLoadMap.set(key, { success: [], error: []});
    }

    const callbackData = textureLoadMap.get(key);
    if(onSuccess) {
        callbackData.success.push(onSuccess);
    }
    if(onError) {
        callbackData.error.push(onError);
    }

}

function onLoadTextureSuccess(key, data, texture) {
    textureMap.set(key, texture);

    let sceneEl = document.querySelector('a-scene');
    let maxAnisotropy = sceneEl.renderer.capabilities.getMaxAnisotropy();
    texture.anisotropy = maxAnisotropy;
        
    if(data !== undefined && data !== null) {
        if(data.mipmaps !== undefined) {
            if(data.mipmaps === false) {
                texture.generateMipmaps = false;
                texture.minFilter = THREE.LinearFilter;
            }
            if (data.minFilter !== undefined) {
                texture.minFilter = data.minFilter;
            }
            if (data.magFilter !== undefined) {
                texture.magFilter = data.magFilter;
            }
            if (data.repeat !== undefined) {
                texture.repeat = data.repeat;
            }
            if (data.offset !== undefined) {
                texture.offset = data.offset;
            }
        }
    }

    invokeAndCleanTextureLoadedCallback(key, 'success', texture);
}

function onLoadTextureError(key, error) {
    invokeAndCleanTextureLoadedCallback(key, 'error', error);
}

function invokeAndCleanTextureLoadedCallback(key, obj, data) {
    if(textureLoadMap.has(key)) {
        const callbackData = textureLoadMap.get(key);
        callbackData[obj].forEach(element => {
            element(data);
        });
        callbackData.success = null;
        callbackData.error = null;
        textureLoadMap.delete(key);
    }
}

function hasTexture(key) {
    return textureMap.has(key);
}

function disposeTexture(key) {
    if(textureMap.has(key)) {
        let texture = textureMap.get(key);
        textureMap.delete(key);
        texture.dispose();
    }
}

init();

export {
    // initialization
    initializeCache,
    // geometry cache functions
    getGeometry,
    hasGeometry,
    disposeGeometry,
    // material cache functions
    getMaterial,
    hasMaterial,
    disposeMaterial,
    // texture cache functions
    getTexture,
    hasTexture,
    disposeTexture,
    isLoadingTexture
};