class GbMinimapActiveMarker extends HTMLElement {
  constructor() {
    super();

    this.render = this.render.bind(this);
    this.updateRotation = this.updateRotation.bind(this);
    this.currentRotation = window.showroomRotation || this.getAttribute('initialrotation');
  }

  connectedCallback() {      
    this.render();
    
    window.addEventListener('geberit.rotateMap', this.updateRotation);
  }
  
  disconnectedCallback() {
    window.removeEventListener('geberit.rotateMap', this.updateRotation);
  }


  updateRotation() {
    this.currentRotation = document.querySelector('a-camera').getAttribute('rotation').y;
    window.showroomRotation = this.currentRotation;
    this.render();
  }

  render() {
    const minimapX = this.getAttribute('x');
    const minimapY = this.getAttribute('y');

    this.innerHTML = `
      <div
          class="gb-minimap-dot gb-minimap-dot--current"
          style="top: ${minimapY}px; left: ${minimapX}px; transform: rotate(${150-this.currentRotation}deg);"
      >
          <div class="gb-minimap-light-beam"></div>
      </div>
    `;
  }
}

customElements.define('gb-minimap-activemarker', GbMinimapActiveMarker);