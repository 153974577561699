import { isEmpty } from '../../utils/helper.js';
import { approximately } from '../../utils/mathf.js';

let activeInfobox = null;
function setActiveInfobox(infobox) {
    if (activeInfobox !== null) {
        const tmp = activeInfobox;
        activeInfobox = null;
        tmp.close();
    }
    activeInfobox = infobox;
}

AFRAME.registerComponent('info-box', {
    schema: {
        content: { default: undefined },
        parent: { default: undefined },
        animationDuration: { default: 0.25 }
    },

    init: function () {
        this.isOpen = false;
        this.isVisble = true;
        this.inputPosition = new THREE.Vector2();
        this.inputDistance = 0.0;

        let root = document.querySelector('#info-box-container');

        // create a container element
        this.pivot = document.createElement('div');
        this.pivot.classList.add('info-box__pivot');

        // create container to scale the object
        this.scalePivot = document.createElement('div');
        this.scalePivot.classList.add('info-box__pivot__scale');
        this.pivot.appendChild(this.scalePivot);

        this.scalePivot.style.transition = 'transform ' + this.data.animationDuration + 's ease-in-out';

        let arrow = document.createElement('div');
        arrow.classList.add('info-box__arrow');
        this.scalePivot.appendChild(arrow);

        root.appendChild(this.pivot);

        this.bindMethods();
        this.close(0.0);
    },

    remove: function () {
        let root = document.querySelector('#info-box-container');
        if (this.isOpen) {
            let duration = 0.1;
            this.scalePivot.style.transition = 'transform ' + duration + 's ease-in-out';
            this.close(duration);

            let timeout = duration * 1000.0;
            setTimeout(() => {
                root.removeChild(this.pivot);
            }, timeout);
        } else {
            root.removeChild(this.pivot);
        }
    },

    bindMethods: function () {
        this.onMouseDown = AFRAME.utils.bind(this.onMouseDown, this);
        this.onMouseMove = AFRAME.utils.bind(this.onMouseMove, this);
        this.onMouseUp = AFRAME.utils.bind(this.onMouseUp, this);
        this.onTouchStart = AFRAME.utils.bind(this.onTouchStart, this);
        this.onToucheMove = AFRAME.utils.bind(this.onToucheMove, this);
        this.onTouchEnd = AFRAME.utils.bind(this.onTouchEnd, this);
        this.onShowContentClicked = AFRAME.utils.bind(this.onShowContentClicked, this);
        this.animate = AFRAME.utils.bind(this.animate, this);
    },

    update: function () {
        if (!isEmpty(this.data.content)) {
            this.renderContent(this.data.content);
        }
    },

    animate: function () {
        if (this.data.parent !== undefined) {
            let camera = document.querySelector('a-camera').getObject3D('camera');

            let cameraDirection = new THREE.Vector3();
            camera.getWorldDirection(cameraDirection);

            let objectDirection = this.data.parent.el.object3D.position.clone();
            objectDirection.normalize();

            let dot = cameraDirection.dot(objectDirection);
            let visible = dot > 0.0;
            this.setVisible(visible);

            let vector = this.worldToScreenPosition(this.data.parent.el.object3D.position);
            this.pivot.style.transform = 'translate(' + vector.x + 'px,' + vector.y + 'px)';
        }

        if (this.isOpen) {
            requestAnimationFrame(this.animate);
        }
    },

    renderContent: function (data) {
        let main = data['3d-content'];

        let content = document.createElement('div');
        content.classList.add('info-box__content');

        let header = document.createElement('div');
        header.classList.add('info-box__content__headline');
        content.appendChild(header);

        if (!isEmpty(main.title)) {
            let title = document.createElement('h4');
            title.insertAdjacentHTML('afterbegin', main.title);
            header.appendChild(title);
        }

        let contentType = 'none';

        if (!isEmpty(main.preview)) {
            contentType = main.preview['content-type'];

            if (!isEmpty(main.preview.img) && !isEmpty(main.preview.img.url)) {
                let imgContainer;
                if (contentType === 'video') {
                    imgContainer = document.createElement('button');
                    imgContainer.onclick = this.onShowContentClicked;
                } else {
                    imgContainer = document.createElement('div');
                }
                imgContainer.classList.add('info-box__image-container');
                header.appendChild(imgContainer);

                let img = document.createElement('img');
                img.setAttribute('src', main.preview.img.url);
                img.setAttribute('alt', main.preview.img.alt);
                img.classList.add('info-box__image-container__main');
                imgContainer.appendChild(img);

                if (contentType === 'video') {
                    let playButton = document.createElement('img');
                    playButton.setAttribute('src', 'src/assets/images/web/play-button.svg');
                    playButton.classList.add('info-box__play-button');
                    imgContainer.appendChild(playButton);
                }
            }
        }

        let scrollview = document.createElement('div');
        scrollview.classList.add('info-box__content__scrollview');
        content.appendChild(scrollview);

        if (!isEmpty(main.text)) {
            let text = document.createElement('p');
            text.insertAdjacentHTML('afterbegin', main.text);
            scrollview.appendChild(text);
        }

        if (!isEmpty(contentType) && contentType !== 'none' && contentType !== 'video') {
            let footer = document.createElement('div');
            footer.classList.add('info-box__content__bottom');
            content.appendChild(footer);

            let button = document.createElement('button');
            button.classList.add('button');
            button.classList.add('button--transparent');
            button.innerText = main['btn-label'];

            let position = this.el.object3D.position;
            position.x -= 1.5;
            position.y += 1.5;
            button.onclick = this.onShowContentClicked;
            footer.appendChild(button);
        }

        this.scalePivot.innerHtml = '';
        this.scalePivot.appendChild(content);
    },

    onShowContentClicked: function () {
        if (!isEmpty(this.data.content)) {
            let event = new CustomEvent('onhotspotclick', { detail: this.data.content.id });
            window.dispatchEvent(event);
        }
    },

    open: function () {
        this.scalePivot.style.transform = 'scale(1, 1)';
        this.isOpen = true;

        setActiveInfobox(this);

        this.animate();

        if (this.data.parent !== undefined) {
            let vector = this.worldToScreenPosition(this.data.parent.el.object3D.position);

            let rx = vector.x / window.innerWidth;
            let ry = vector.y / window.innerHeight;

            let a = Math.max(Math.min(Math.floor(rx * 3.0), 2), 0);
            let b = Math.max(Math.min(Math.floor(ry * 3.0), 2), 0);

            if (a === 1 && b === 1) {
                if (rx < 0.5) {
                    a = 0;
                } else {
                    a = 2;
                }
            }

            let x = 100 - (a * 50);
            let y = 100 - (b * 50);

            let ox = a - 1;
            let oy = b - 1;

            let f1 = Math.abs((ox + oy) % 2) ? 1.41 : 1.0;
            let f2 = Math.abs((ox + oy) % 2) ? 1.0 : 1.41;

            let tx = ox * f1;
            let ty = oy * f1;
            let vx = Math.sign(ox) * 0.75 / f2;
            let vy = Math.sign(oy) * 0.75 / f2;

            let content = this.scalePivot.querySelector('.info-box__content');
            content.style.transform = 'translate(calc(' + x + '% - ' + (tx + vx) + 'rem), calc(' + y + '% - ' + (ty + vy) + 'rem))';

            let angle = 180.0 + Math.atan2(oy, ox) * THREE.MathUtils.RAD2DEG;
            let arrow = this.scalePivot.querySelector('.info-box__arrow');
            arrow.style.transform = 'translate(calc(0% - ' + vx + 'rem), calc(-50% - ' + vy + 'rem)) rotate(' + angle + 'deg)';

            let verticalOffsetFactor = oy > 0.5 ? 1.5 : 1.0;
            let camera = document.querySelector('[camera]').components['camera-controls'];
            let localPosition = new THREE.Vector3(-ox * 1.5, oy * verticalOffsetFactor * 1.5, 0.0);
            let worldPosition = this.data.parent.pivotEl.object3D.localToWorld(localPosition);
            camera.lookAt(worldPosition);

            /* show hotspot id on click */
            if (process.env.NODE_ENV === 'development') {
                console.log(this.data.content.id);
            }
        }

        setTimeout(() => {
            let scene = document.querySelector('a-scene');
            scene.addEventListener('mousedown', this.onMouseDown);
            scene.addEventListener('mousemove', this.onMouseMove);
            scene.addEventListener('mouseup', this.onMouseUp);
            scene.addEventListener('touchstart', this.onTouchStart);
            scene.addEventListener('touchmove', this.onToucheMove);
            scene.addEventListener('touchend', this.onTouchEnd);
        }, 1);
    },

    close: function (duration) {
        setActiveInfobox(null);

        let scene = document.querySelector('a-scene');
        scene.removeEventListener('mousedown', this.onMouseDown);
        scene.removeEventListener('mousemove', this.onMouseMove);
        scene.removeEventListener('mouseup', this.onMouseUp);
        scene.removeEventListener('touchstart', this.onTouchStart);
        scene.removeEventListener('touchmove', this.onToucheMove);
        scene.removeEventListener('touchend', this.onTouchEnd);

        this.scalePivot.style.transform = 'scale(0, 0)';

        let timeout = (duration !== undefined ? duration : this.data.animationDuration) * 1000.0;
        if (approximately(timeout, 0.0)) {
            this.isOpen = false;
        } else {
            setTimeout(() => {
                this.isOpen = false;
            }, timeout);
        }

        let event = new CustomEvent('closed', null);
        this.el.dispatchEvent(event);
    },

    onMouseDown: function (event) {
        if (!this.isValidMouseEvent(event)) {
            return;
        }

        this.inputPosition = new THREE.Vector2(event.pageX, event.pageY);
        this.inputDistance = 0.0;
    },

    onMouseMove: function (event) {
        if (!this.isValidMouseEvent(event)) {
            return;
        }

        let inputPosition = new THREE.Vector2(event.pageX, event.pageY);
        this.inputDistance += this.inputPosition.distanceToSquared(inputPosition);

        this.inputPosition = inputPosition;
    },

    onMouseUp: function (event) {
        if (!this.isValidMouseEvent(event)) {
            return;
        }

        this.checkInputEnd(event);
    },

    isValidMouseEvent: function (event) {
        if (!event.pageX || !event.pageY) {
            return false;
        }

        return true;
    },

    onTouchStart: function (event) {
        if (!this.isValidTouchEvent(event)) {
            return;
        }

        this.inputPosition = new THREE.Vector2(event.touches[0].pageX, event.touches[0].pageY);
        this.inputDistance = 0.0;
    },

    onToucheMove: function (event) {
        if (!this.isValidTouchEvent(event)) {
            return;
        }

        let inputPosition = new THREE.Vector2(event.touches[0].pageX, event.touches[0].pageY);
        this.inputDistance += this.inputPosition.distanceToSquared(inputPosition);

        this.inputPosition = inputPosition;
    },

    onTouchEnd: function (event) {
        if (!this.isValidTouchEvent(event)) {
            return;
        }

        this.checkInputEnd(event);
    },

    isValidTouchEvent: function (event) {
        if (!event.touches) {
            return false;
        }

        if (event.type === "touchend") {
            if (event.touches.length !== 0) {
                return false;
            }
        } else {
            if (event.touches.length !== 1) {
                return false;
            }

            if (!event.touches[0].pageX || !event.touches[0].pageY) {
                return false;
            }
        }

        return true;
    },

    checkInputEnd: function (event) {
        if (this.inputDistance < 10.0) {
            if (!this.IsChildOf(event.target, this.pivot)) {
                this.close();
            }
        }

        this.inputOrigin = new THREE.Vector2();
        this.inputDistance = 0.0;
    },

    onClick: function (event) {
        if (!this.IsChildOf(event.target, this.pivot)) {
            this.close();
        }
    },

    setVisible: function (visible) {
        if (this.isVisble !== visible) {
            this.isVisble = visible;
            if (visible) {
                this.pivot.classList.remove('info-box__pivot--hidden');
            } else {
                this.pivot.classList.add('info-box__pivot--hidden');
            }
        }
    },

    worldToScreenPosition: function (worldPosition) {
        let camera = document.querySelector('a-camera').getObject3D('camera');
        let vector = worldPosition.clone();

        // map to normalized device coordinate (NDC) space
        vector = vector.project(camera);

        // map to 2D screen space
        vector.x = ((vector.x + 1) * window.innerWidth / 2);
        vector.y = ((-vector.y + 1) * window.innerHeight / 2);
        vector.z = 0;

        return vector;
    },

    IsChildOf: function (element, parent) {
        while (element) {
            if (element === parent) {
                return true;
            }
            element = element.parentElement;
        }
        return false;
    }
});