class GbLoadingIndicator extends HTMLElement {
    constructor() {
      super();

      this.active = false;
      this.onSetActive = this.onSetActive.bind(this);
    }

    connectedCallback() {      
      this.innerHTML = `
      <div class="loading-indicator loading-indicator--hidden">
        <gb-backdrop></gb-backdrop>
        <div class="c-loader c-loader--large c-loader--color-white">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>`;

      window.addEventListener('loadingindicatoractive', this.onSetActive);
    }

    disconnectedCallback() {
      window.removeEventListener('loadingindicatoractive', this.onSetActive);
      this.onSetActive(false);
    }
  
    onSetActive(event) {
      const { active } = event.detail;
      if (active === this.active) {
        return;
      }
  
      this.active = active;
      const loadingIndicator = this.querySelector('.loading-indicator');
      if (active) {
        loadingIndicator.classList.remove('loading-indicator--hidden');
      } else {
        loadingIndicator.classList.add('loading-indicator--hidden');
      }
    }
}

customElements.define('gb-loading-indicator', GbLoadingIndicator);