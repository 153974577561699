import { getTexture } from './asset-cache.js';

AFRAME.registerComponent('panorama', {
    schema: {
        src: { default: '' },
        radius: { default: 50.0 }
    },

    init: function() {
        this.frameDelay = 0;
        this.panoramaInitializedCallback = null;

        this.progress = 1.0;
        this.direction = new THREE.Vector3();

        // load a black sample texture
        const texture = getTexture('black', 'src/assets/images/panoramas/black.jpg');

        // construct geometry for two half spheres
        const segments = 64;
        const geometryLeft = new THREE.SphereGeometry(
            this.data.radius,
            segments,
            segments,
            0,
            Math.PI
        );

        const geometryRight = new THREE.SphereGeometry(
            this.data.radius,
            segments,
            segments,
            Math.PI,
            Math.PI
        );

        // create the primary sphere
        this.primaryMaterialLeft = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false,
            depthTest: false,
            side: THREE.BackSide,
        });
        this.primaryMeshLeft = new THREE.Mesh(
            geometryLeft,
            this.primaryMaterialLeft
        );

        this.primaryMaterialRight = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false,
            depthTest: false,
            side: THREE.BackSide,
        });
        this.primaryMeshRight = new THREE.Mesh(
            geometryRight,
            this.primaryMaterialRight
        );

        this.primaryGroup = new THREE.Group();
        this.primaryGroup.add(this.primaryMeshLeft);
        this.primaryGroup.add(this.primaryMeshRight);
        this.primaryGroup.scale.set(-1, 1, 1);

        this.el.setObject3D('primary', this.primaryGroup);

        // create the secondary sphere
        this.secondaryMaterialLeft = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false,
            side: THREE.BackSide,
        });
        this.secondaryMeshLeft = new THREE.Mesh(
            geometryLeft,
            this.secondaryMaterialLeft
        );

        this.secondaryMaterialRight = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false,
            side: THREE.BackSide,
        });
        this.secondaryMeshRight = new THREE.Mesh(
            geometryRight,
            this.secondaryMaterialRight
        );

        this.secondaryGroup = new THREE.Group();
        this.secondaryGroup.add(this.secondaryMeshLeft);
        this.secondaryGroup.add(this.secondaryMeshRight);
        this.secondaryGroup.scale.set(-1, 1, 1);

        this.el.setObject3D('secondary', this.secondaryGroup);

        this.secondaryGroup.visible = false;
    },

    tick: function(_, timeDelta) {
        if(this.frameDelay > 0) {
            this.frameDelay -= 1;
            if(this.frameDelay === 0) {
                if(this.panoramaInitializedCallback) {
                    this.panoramaInitializedCallback();
                    this.panoramaInitializedCallback = null;
                }
            }
            return;
        }

        let progress = this.progress;
        if(progress < 1.0) {
            let direction = this.direction;

            let dt = timeDelta / 1000.0;
            progress = Math.min(progress + dt / 0.35, 1.0);
            
            this.secondaryMaterialLeft.opacity = progress;
            this.secondaryMaterialRight.opacity = progress;
      
            const primaryObject3D = this.primaryGroup;
            const secondaryObject3D = this.secondaryGroup;
            
            if(direction !== undefined) {
                const primaryDirection = direction.clone();
                primaryDirection.multiplyScalar(progress);
                primaryObject3D.position.set(
                    primaryDirection.x,
                    primaryDirection.y,
                    primaryDirection.z
                );

                const secondaryDirection = direction.clone();
                secondaryDirection.multiplyScalar(-(1.0 - progress));
                secondaryObject3D.position.set(
                    secondaryDirection.x,
                    secondaryDirection.y,
                    secondaryDirection.z
                );

                const primaryScale = 1.0 + 0.25 * progress;
                primaryObject3D.scale.set(-primaryScale, primaryScale, primaryScale);

                const secondaryScale = 1.0 - 0.25 * (1.0 - progress);
                secondaryObject3D.scale.set(-secondaryScale, secondaryScale, secondaryScale);
            }

            if(progress > 0.999) {
                const oldTextureLeft = this.primaryMaterialLeft.map;
                const oldTextureRight = this.primaryMaterialRight.map;
                if(oldTextureLeft) {
                    oldTextureLeft.dispose();
                }
                if(oldTextureRight) {
                    oldTextureRight.dispose();
                }

                const textureLeft = this.secondaryMaterialLeft.map;
                const texextureRight = this.secondaryMaterialRight.map;
                this.primaryMaterialLeft.map = textureLeft;
                this.primaryMaterialRight.map = texextureRight;
                this.primaryMaterialLeft.needsUpdate = true;
                this.primaryMaterialRight.needsUpdate = true;
        
                primaryObject3D.scale.set(-1.0, 1.0, 1.0);
                secondaryObject3D.scale.set(-1.0, 1.0, 1.0);

                primaryObject3D.position.set(0, 0, 0);
                secondaryObject3D.position.set(0, 0, 0);

                this.secondaryMaterialLeft.opacity = 0.0;
                this.secondaryMaterialRight.opacity = 0.0;
                secondaryObject3D.visible = false;
            }

            this.progress = progress;
        }
    },

    setPanorama(textureLeft, textureRight, targetPosition, panoramaInitializedCallback) {
        this.secondaryMaterialLeft.map = textureLeft;
        this.secondaryMaterialRight.map = textureRight;
        this.secondaryMaterialLeft.needsUpdate = true;
        this.secondaryMaterialRight.needsUpdate = true;
        this.secondaryMaterialLeft.opacity = 0.0;
        this.secondaryMaterialRight.opacity = 0.0;

        if(targetPosition !== undefined) {
            if(targetPosition.lengthSq() < 0.001) {
                this.direction = undefined;
            } else {
                this.direction = targetPosition.clone();
                this.direction.y = 0.0;
                this.direction.normalize();
                this.direction.multiplyScalar(-5.0);
            } 
        } else {
            this.direction = undefined;
        }

        this.primaryGroup.scale.set(-1.0, 1.0, 1.0);

        this.secondaryGroup.visible = true;
        this.secondaryGroup.scale.set(-0.75, 0.75, 0.75);

        this.panoramaInitializedCallback = panoramaInitializedCallback;
        this.frameDelay = 3;
        this.progress = 0.0;
    }
});