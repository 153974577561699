import shortid from 'shortid';
import { isEmpty } from '../utils/helper.js';
import { pushVirtualPageview, popVirtualPageview } from '../utils/analytics';

class GbLightbox extends HTMLElement {
  constructor() {
    super();
    this.closeLightbox = this.closeLightbox.bind(this);
    this.renderImageGallery = this.renderImageGallery.bind(this);
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.showLightbox = this.showLightbox.bind(this);
    this.hideLightbox = this.hideLightbox.bind(this);
    this.renderContent = this.renderContent.bind(this);

    window.addEventListener('onhotspotclick', this.showLightbox);

    this._content = {};
    this._hotspot = {};
    this._hotspotId = null;
  }

  set content(value) {
    this._content = value;
  }

  get content() {
    return this._content;
  }

  connectedCallback() {
    this.className = 'gb-lightbox gb-lightbox--hidden';
    let html = `<gb-overlay class="${this.video ? 'gb-overlay--black' : ''}">
                        <div class="gb-overlay__wrapper__button">
                            <button class="close-button"><i class="web20-icon web20-icon-close"></i></button>
                        </div>
                        <div class="gb-overlay__wrapper__content">
                            <div class="gb-lightbox__content"></div>
                         </div>
                    </gb-overlay>
                          `;

    this.insertAdjacentHTML('afterbegin', html);

    let btn = this.querySelector('button');
    btn.addEventListener('click', this.closeLightbox);
  }

  disconnectedCallback() {
    let btn = this.querySelector('button');
    btn.removeEventListener('click', this.closeLightbox);

    window.removeEventListener('onhotspotclick', this.showLightbox);
  }

  closeLightbox() {
    this.hideLightbox();
    let node = this.querySelector('gb-video-player');
    if (!isEmpty(node)) {
      let elementId = node.getAttribute('element-id');
      if (window.VideoPlayer && window.VideoPlayer.Collection.getPlayerById(elementId)) {
        window.VideoPlayer.Collection.removePlayerById(elementId);
        node.parentNode.removeChild(node);
      }
    }
  }

  showLightbox(event) {
    this._hotspotId = event.detail;
    this._hotspot = this.getHotspotById(this._hotspotId);
    if (!isEmpty(this._hotspot)) {
      this.renderContent();
      this.classList.remove('gb-lightbox--hidden');
    }
  }

  getHotspotById(id) {
    return this._content.find((hotspot) => hotspot.id === id);
  }

  hideLightbox() {
    this.classList.add('gb-lightbox--hidden');

    popVirtualPageview();
  }

  renderContent() {
    let { 'content-type': contentType } = this._hotspot['3d-content'].preview;
    if (contentType === 'none') {
      return;
    }
    let template = this.querySelector('.gb-lightbox__content');
    template.innerHTML = '';
    let html = contentType === 'video' ? this.renderVideoPlayer() : this.renderImageGallery();
    template.insertAdjacentHTML('afterbegin', html);

    const url = `lightbox-${contentType}-${this._hotspot.id}`;
    const pageTitle =
      contentType === 'video'
        ? `Videoplayer: ${this._hotspot.id}`
        : `Image-Gallery: ${this._hotspot.id}`;
    pushVirtualPageview(url, pageTitle);
  }

  renderImageGallery() {
    let overlay = this.querySelector('gb-overlay');
    overlay.classList.remove('gb-overlay--black');
    let elem = document.createElement('gb-image-gallery');
    elem.content = this._hotspot['image-gallery'];
    return elem.outerHTML;
  }

  renderVideoPlayer() {
    let overlay = this.querySelector('gb-overlay');
    overlay.classList.add('gb-overlay--black');
    let elem = document.createElement('gb-video-player');
    elem.setAttribute('element-id', shortid.generate());
    elem.setAttribute('video-id', this._hotspot.video['video-id']);
    return elem.outerHTML;
  }
}

customElements.define('gb-lightbox', GbLightbox);
