import data from '../../assets/data/config.json';

let config = (function() {
    let routes = '';
    Object.keys(data.urls).forEach(url =>  { routes += url; routes += '|'; });
    routes = routes.slice(0, -1);

    return {
        urls: data.urls,
        routes: routes,
        master: 'master/master.json'
    };
}());

export default config;