import { getGeometry, getMaterial, getTexture } from './asset-cache.js';

AFRAME.registerComponent('viewpoint-mark-button', {
    schema: {
        viewpoint: { default: '' },
        iconPath : { default: 'src/assets/images/sprites/hotspot-lens-in.png' },
        hoverDuration: { default: 0.2 },
        clickDuration: { default: 0.25 },
        size: { default: 0.4 }
    },

    init: function() {
        this.baseScale = 1.3;
        this.bounceAnimation = {
            progress: Math.random() * 5.0,
            value: 0.0,
            scale: 0.4,
            delay: 5.0,
            duration: 0.75
        };

        this.isHovered = false;
        this.hoverAnimation = {
            progress: 0.0,
            value: 0.0,
            scale: 0.25,
            bounceFactor: 0.2,
            bounceValue: 1.0
        };

        this.isClicked = false;
        this.clickAnimation = {
            progress: 0.0,
            value: 0.0,
            scale: 0.25
        };

        // element references
        let el = this.el;
        
        // create a rotation pivot
        let pivotEl = document.createElement('a-entity');
        el.appendChild(pivotEl);

        // create a content container
        let contentEl = document.createElement('a-entity');
        pivotEl.appendChild(contentEl);

        // load and setup textures
        let mainTexture = getTexture('vmb-tex-main', 'src/assets/images/sprites/hotspot-main.png');
        let backgroundTexture = getTexture('vmb-tex-back', 'src/assets/images/sprites/hotspot-background.png');
        let iconTexture = getTexture(this.data.iconPath, this.data.iconPath);

        let size = this.data.size;

        let geometry = getGeometry('vmb-geo-all', 'plane', { width: size, height: size });
        
        // create background mesh
        let backgroundMaterial = getMaterial('vmb-mat-back', { map: backgroundTexture, side: THREE.DoubleSide, transparent: true, depthWrite: false } );
        let backgroundMesh = new THREE.Mesh(geometry, backgroundMaterial);
        backgroundMesh.scale.set(this.baseScale, this.baseScale, this.baseScale);
        backgroundMesh.position.set(0,0,-0.1);
        contentEl.setObject3D('background', backgroundMesh);

        // create circle mesh
        let circleMaterial = getMaterial('vmb-mat-main', { map: mainTexture, side: THREE.DoubleSide, transparent: true, depthWrite: false } );
        let circleMesh = new THREE.Mesh(geometry, circleMaterial);
        contentEl.setObject3D('circle', circleMesh);

        // create icon mesh
        let iconMaterial = getMaterial('vmb-mat-icon_' + this.data.iconPath, { map: iconTexture, side: THREE.DoubleSide, transparent: true, depthWrite: false } );
        let iconMesh = new THREE.Mesh(geometry, iconMaterial);
        iconMesh.position.set(0,0,0.1);
        contentEl.setObject3D('icon', iconMesh);

        // create a invisble hitbox for hover and click interactions
        let hitbox = document.createElement('a-entity');
        hitbox.setAttribute('id', 'clickable');
        hitbox.setAttribute("geometry", { primitive: 'sphere', radius: size * 1.25 });
        hitbox.setAttribute("material", { opacity: 0.0, transparent: true, depthWrite: false });
        pivotEl.appendChild(hitbox);

        this.bindMethods();
        this.addEventListener();
    },

    remove: function() {
        this.removeEventListener();
    },

    bindMethods: function() {
        this.onMouseEnter = AFRAME.utils.bind(this.onMouseEnter, this);
        this.onMouseLeave = AFRAME.utils.bind(this.onMouseLeave, this);
        this.onClick = AFRAME.utils.bind(this.onClick, this);
    },

    addEventListener: function() {
        let hitboxEl = this.el.querySelector('#clickable');

        hitboxEl.addEventListener('mouseenter', this.onMouseEnter);
        hitboxEl.addEventListener('mouseleave', this.onMouseLeave);
        hitboxEl.addEventListener('click', this.onClick);
    },

    removeEventListener() {
        let hitboxEl = this.el.querySelector('#clickable');
        
        hitboxEl.removeEventListener('mouseenter', this.onMouseEnter);
        hitboxEl.removeEventListener('mouseleave', this.onMouseLeave);
        hitboxEl.removeEventListener('click', this.onClick);
    },

    tick: function(time, timeDelta) {
        // convert from ms to s
        let dt = timeDelta / 1000.0;

        // update hotspot
        this.updateLookAt();
        this.updateHover(dt);
        this.updateClick(dt);
        this.updateBounce(dt);
        this.applyAnimation();
    },

    updateLookAt: function() {
        // get a reference to the camera and rotate towards camera
        let pivotEl = this.el.querySelector('a-entity');
        let camera = document.querySelector('[camera]').getObject3D('camera');
        pivotEl.object3D.lookAt(camera.position);
    },

    updateHover: function(dt) {
        let isHovered = this.isHovered;
        let progress = this.hoverAnimation.progress;

        // abort if animation is done
        if(isHovered && progress > 0.999) {
            return false;
        }

        if(!isHovered && progress < 0.001) {
            return false;
        }

        let duration = this.data.hoverDuration;
        let scale = this.hoverAnimation.scale;
        let bounceFactor = this.hoverAnimation.bounceFactor;

        if(isHovered) {
            progress = Math.min(progress + dt / duration, 1.0);
        } else {
            progress = Math.max(progress - dt / duration, 0.0);
        }

        let value = Math.sin(progress * Math.PI * 0.5);
        this.hoverAnimation.value = scale * value;
        this.hoverAnimation.bounceValue = bounceFactor + (1.0 - bounceFactor) * (1.0 - value);
        this.hoverAnimation.progress = progress;

        return true;
    },

    updateClick: function(dt) {
        let isClicked = this.isClicked;
        let progress = this.clickAnimation.progress;

        // abort if animation is done
        if(!isClicked && progress < 0.001) {
            return false;
        }

        let duration = this.data.clickDuration;
        let scale = this.clickAnimation.scale;

        progress = Math.min(progress + dt / duration, 1.0);

        let value = Math.sin(progress * Math.PI);
        this.clickAnimation.value = scale * value;

        if(progress > 0.999) {
            isClicked = false;
            progress = 0.0;
        }

        this.isClicked = isClicked;
        this.clickAnimation.progress = progress;

        return true;
    },

    updateBounce: function(dt) {
        let progress = this.bounceAnimation.progress;
        let delay = this.bounceAnimation.delay;
        
        progress += dt;
        if(progress >= delay) {
            let scale = this.bounceAnimation.scale;
            let hoverFactor = this.hoverAnimation.bounceValue;
            let duration = this.bounceAnimation.duration;
            let value = Math.min((progress - delay) / duration, 1.0);
            
            this.bounceAnimation.value = scale * hoverFactor * Math.sin(value * Math.PI);
            
            if(value > 0.999) {
                progress = 0.0;
                this.bounceAnimation.value = 0.0;
            }
        }

        this.bounceAnimation.progress = progress;
    },

    applyAnimation: function() {
        let pivotEl = this.el.querySelector('a-entity');
        let contentEl = pivotEl.querySelector('a-entity');
        let background = contentEl.getObject3D('background');

        // animate background
        let baseScale = this.baseScale;
        let hoverScale = this.hoverAnimation.value;
        let bounceScale = this.bounceAnimation.value;
        let scale = baseScale + hoverScale + bounceScale;
        background.scale.set(scale, scale, scale);
        

        // update main object
        let clickScale = this.clickAnimation.value;
        scale = 1.0 + clickScale;
        contentEl.object3D.scale.set(scale, scale, scale);
    },

    onMouseEnter: function() {
        // object is now hovered
        this.isHovered = true;
        this.hoverAnimation.progress = 0.0;
    },

    onMouseLeave: function() {
        // object is no longer hovered
        this.isHovered = false;
    },

    onClick: function() {
        this.isClicked = true;
        this.clickAnimation.progress = 0.0;
        let viewpoint = this.data.viewpoint;
        var event = new CustomEvent('loadviewpoint', { detail: { viewpointId: viewpoint } });
        window.dispatchEvent(event);
    },
});