class GbChevrons extends HTMLElement {
    constructor() {
        super(); 

        this.downLeft = this.downLeft.bind(this);
        this.upLeft = this.upLeft.bind(this);
        this.downRight = this.downRight.bind(this);
        this.upRight = this.upRight.bind(this);
    }

    connectedCallback() {
        this.innerHTML = `
        <div class="gb-chevrons">
            <div class="gb-chevrons__left">
                <button class="chevron" id="left" unselectable="yes">
                    <i class="web20-icon web20-icon-chevron-large-left"></i>
                </button>
            </div>
            <div class="gb-chevrons__right">
                <button class="chevron" id="right" unselectable="yes">
                    <i class="web20-icon web20-icon-chevron-large-right"></i>
                </button>
            </div>
        </div>`;

        let btnLeft = this.querySelector('#left');
        btnLeft.addEventListener("mousedown", this.downLeft);
        btnLeft.addEventListener("touchstart", this.downLeft);

        let btnRight = this.querySelector('#right');
        btnRight.addEventListener("mousedown", this.downRight);
        btnRight.addEventListener("touchstart", this.downRight);
    }

    disconnectedCallback() {
        let btnLeft = this.querySelector('#left');
        btnLeft.removeEventListener("mousedown", this.downLeft);
        btnLeft.removeEventListener("touchstart", this.downLeft);
  
        let btnRight = this.querySelector('#right');
        btnRight.removeEventListener("mousedown", this.downRight);
        btnRight.removeEventListener("touchstart", this.downRight);

        window.removeEventListener("mouseup", this.upLeft);
        window.removeEventListener("touchend", this.upLeft);

        window.removeEventListener("mouseup", this.upRight);
        window.removeEventListener("touchend", this.upRight);
    }

    downLeft() {
        window.addEventListener("mouseup", this.upLeft);
        window.addEventListener("touchend", this.upLeft);
        this.dispatchEvent(true, 1);
    };

    upLeft() {
        window.removeEventListener("mouseup", this.upLeft);
        window.removeEventListener("touchend", this.upLeft);
        this.dispatchEvent(false, 1);
    }

    downRight() {
        window.addEventListener("mouseup", this.upRight);
        window.addEventListener("touchend", this.upRight);
        this.dispatchEvent(true, 2);
    };

    upRight() {
        window.removeEventListener("mouseup", this.upRight);
        window.removeEventListener("touchend", this.upRight);
        this.dispatchEvent(false, 2);
    }

    dispatchEvent(active, direction) {
        var event = new CustomEvent('rotatecamera', { detail: { active: active, direction: direction } });
        window.dispatchEvent(event);
    }    
}

customElements.define('gb-chevrons', GbChevrons);