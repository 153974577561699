AFRAME.registerComponent('fade', {
    schema: {
        defaultScale: { default: 1.0 },
        fadeInDuration: { default: 0.35 },
        fadeInDelay: { default: 0.0 },
        fadeOutDuration: { default: 0.15 },
        fadeOutDelay: { default: 0.0 },
        removeOnFadeOut: { default: true }
    },

    init: function() {
        this.active = false;
        this.progress = 1.0;
        this.delay = 0.0;

        let scale = 0.0001;
        this.el.object3D.scale.set(scale, scale, scale);
    },

    play: function() {
        this.active = true;
        this.progress = 0.0;
        this.delay = 0.0;
    },

    fadeOut: function() {
        this.active = false;
        this.progress = 0.0;
        this.delay = 0.0;
    },

    tick: function(time, timeDelta) {
        let progress = this.progress;

        if(progress < 1.0) {
            let active = this.active;
            let delay = this.delay;
            let targetDelay = active ? this.data.fadeInDelay : this.data.fadeOutDelay;
            let duration = active ? this.data.fadeInDuration : this.data.fadeOutDuration;
            let defaultScale = this.data.defaultScale;
            let dt = timeDelta / 1000.0;

            if(delay < targetDelay) {
                delay += dt;
                this.delay = delay;
            } else {
                progress = Math.min(progress + dt / duration, 1.0);

                let scale;
                if(active) {
                    scale = this.easeBounce(progress);
                } else {
                    scale = 1.0 - this.easeOut(progress);
                }
                scale = Math.max(scale * defaultScale, 0.0001);
                this.el.object3D.scale.set(scale, scale, scale);
    
                this.progress = progress;
            }

            if(!active && progress > 0.999 && this.data.removeOnFadeOut) {
                this.el.parentNode.removeChild(this.el);
            }
        }
    },

    easeBounce: function(value) {
        let threshold = 0.8;
        let bounceAmount = 0.1;

        if(value < threshold) {
            value /= threshold;
            return Math.sin(value * Math.PI * 0.5) * (1.0 + bounceAmount);
        } else {
            value = (value - threshold) / (1.0 - threshold);
            return (1.0 + bounceAmount) - bounceAmount * (1.0 - Math.cos(value * Math.PI * 0.5));
        }
    },

    easeOut: function(value) {
        return Math.sin(value * Math.PI * 0.5);
    }
});