import { isEmpty } from "../utils/helper";

class GbImageGallery extends HTMLElement {
    constructor() {
        super();
        this.render = this.render.bind(this);
        this._content = {};
    }

    set content(value) {
        this._content = value;
        this.render();
    }

    connectedCallback() {
        this.className = 'gb-image-gallery';
    }

    render() {
        if (isEmpty(this._content)) {
            return;
        }
        this.innerHTML = '';
        let html = `
                            <div class="gb-image-gallery__scroll-content">
                                <div class="gb-image-gallery__container">
                                    ${this.renderHeadlines()}
                                    ${this.renderContentElements()}
                                </div>
                            </div>
                          `;
        this.insertAdjacentHTML('afterbegin', html);
    }

    renderHeadlines() {
        const { title, subtitle } = this._content;
        let div = document.createElement('div');
        div.innerHTML = `<h2>${title}<b>${subtitle}</b></h2>`;

        return div.innerHTML;
    }

    renderContentElements() {
        const { content } = this._content;
        let div = document.createElement('div');
        div.className = 'gb-image-gallery__wrapper';
        content.forEach(item =>
            {
                let elem = document.createElement('gb-content-element');
                elem.content = item;
                div.innerHTML += elem.outerHTML;
            }
        );

        return div.outerHTML;
    }
}

customElements.define('gb-image-gallery', GbImageGallery);