import config from "../app/config";

function getUrl() {
    if (window.location.pathname.length > 1) {
        let path = window.location.pathname.split('/')[1];
        if (!config.urls[path] && path.match(/\w{2}-\w{2}/, "gi")) {
            path = path.substring(0,3) + path.substring(3,5).toUpperCase();
        }
        let jsonPath = `${config.urls[path].country}/${config.urls[path].country}_${config.urls[path].language}.json`;
        return `src/assets/data/contents/markets/${jsonPath}`;
    }
    return `src/assets/data/contents/markets/${config.master}`;
}

export default function fetchContent() {
    let url = getUrl();
    return fetch(url).then(function(response) {
        return response.json();
    }).then(function(json) {
        return json;
    }).catch(function(err) {
        console.log('Fetch problem: ' + err.message);
        return {};
    });
}

export function fetchViewpointById(viewPointId) {
    let url = 'src/assets/data/contents/viewpoints/' + viewPointId + '.json';

    return fetch(url).then(function(response) {
        return response.json();
    }).then(function(json) {
        return json;
    }).catch(function(err) {
        console.log('Fetch problem: ' + err.message);
        return {};
    });
}

export function fetchMinimap() {
    let path = window.location.pathname.split('/')[1];
    if (!config.urls[path] && path.match(/\w{2}-\w{2}/, "gi")) {
        path = path.substring(0,3) + path.substring(3,5).toUpperCase();
    }
    let url = `src/assets/data/contents/minimaps/${config.urls[path].country}.json`;

    return fetch(url).then(function(response) {
        return response.json();
    }).then(function(json) {
        return json;
    }).catch(function(err) {
        console.log('Fetch problem: ' + err.message);
        return {};
    });
}
