import fetchContent from "../service/service";
import { isEmpty } from "../utils/helper";

class Provider extends HTMLElement {
    constructor() {
        super();
        this._content = {};

        this.componentMapping = {
            header: 'gb-header',
            'root-viewpoint-id': 'gb-webgl-content',
            hotspots: 'gb-lightbox',
            introduction: 'gb-intro',
            minimap: 'gb-minimap',
            instructions: 'gb-tutorial',
            footer: 'gb-footer',
        };

        this.selectorMapping = {
            'gb-header': 'header',
            'gb-webgl-content': 'root-viewpoint-id',
            'gb-lightbox': 'hotspots',
            'gb-intro': 'introduction',
            'gb-minimap': 'minimap',
            'gb-tutorial': 'instructions',
            'gb-footer': 'footer',
        };

    }

    set content(value) {
        this._content = value;
    }

    get content() {
        return this._content;
    }

    connectedCallback() {
        const root =  this; // document.querySelector('#root');
        fetchContent("de", "de").then((json) => {
            this._content = json;
            Object.keys(this._content).map((item) => {
                if (this.componentMapping[item]) {
                    let element = document.createElement(this.componentMapping[item]);
                    element.content = item === 'root-viewpoint-id' ? this._content : this._content[item];
                    root.appendChild(element);
                }
            });
            this.updateTitle();
        });
    }

    setContent() {
        const root =  this; // document.querySelector('#root');
        let elements = Array.from(root.children);
        elements.map(node => {
            if (this.selectorMapping[node.localName]) {
                node.content =
                    node.localName === 'gb-webgl-content' ?
                        this._content : this._content[this.selectorMapping[node.localName]];
            }
        });
        this.updateTitle();
    }

    updateTitle() {
        const title = this.content['website-title'];
        if(!isEmpty(title)) {
            document.title = title;
        }
    }

    onLanguageChange() {
        fetchContent("de", "en").then((json) => {
            this._content = json;
            this.setContent();
        });
    }
}

customElements.define('gb-provider', Provider);

