import { isEmpty, isMobile } from '../utils/helper';
import { fetchMinimap } from '../service/service';

class GbMinimap extends HTMLElement {
  constructor() {
    super();
    this.render = this.render.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.addEventListener = this.addEventListener.bind(this);
    this.clearHTML = this.clearHTML.bind(this);
    this.jumpToView = this.jumpToView.bind(this);
    this.updateMinimap = this.updateMinimap.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleOpenMap = this.handleOpenMap.bind(this);
    this.removeEventListener = this.removeEventListener.bind(this);
    this.openGallery = false;
    this.open = true;
    this.currentMarker = null;
    this._content = {};
    this._galleryItems = [];
    this.currentRotation = 0;
    this.isMobile = false;
  }

  set content(value) {
    fetchMinimap().then((json) => {
      this._galleryItems = json;
      this._content = value;
      this.currentMarker = this._content['root-viewpoint-id'];
      this.currentRotation = this._content['initial-rotation'];
      this.afterConnectedCallback && this.render();
    });
  }

  get content() {
    return this._content;
  }

  connectedCallback() {
    this.isMobile = isMobile();
    this.open = !this.isMobile;
    this.render();
    this.addEventListener();
    this.afterConnectedCallback = true;

  }

  disconnectedCallback() {
    this.removeEventListener();
  }

  handleClick() {
    this.openGallery = !this.openGallery;
    this.render();
  }

  handleOpenMap() {
    this.open = !this.open;
    this.render();
  }

  handleResize() {
    if (isMobile() && !this.isMobile || !isMobile() && this.isMobile) {
      this.isMobile = isMobile();
      this.open = !this.isMobile;
      this.clearHTML();
      this.render();
    }
  }

  addEventListener() {
    let btn1 = this.querySelector('.open-grid');
    if (btn1) btn1.addEventListener('click', this.handleClick);

    let btn2 = this.querySelector('.button-open-map');
    if (btn2) btn2.addEventListener('click', this.handleOpenMap);

    let btn3 = this.querySelector('.close-button');
    if (btn3) btn3.addEventListener('click', this.handleOpenMap);

    window.addEventListener('geberit.hideMinimapMenu', this.closeMenu);
    window.addEventListener('loadviewpoint', this.updateMinimap);
    window.addEventListener('resize', this.handleResize);

    const galleryItems = this.querySelectorAll('.gb-minimap-dot');
    galleryItems.forEach((item) => {
      const id = item.getAttribute('data-id');
      item.addEventListener('click', () => {
        this.jumpToView(id);
      });
    });
  }

  removeEventListener() {
    let btn1 = this.querySelector('.open-grid');
    if (btn1) btn1.removeEventListener('click', this.handleClick);

    let btn2 = this.querySelector('.button-open-map');
    if (btn2) btn2.removeEventListener('click', this.handleOpenMap);

    let btn3 = this.querySelector('.close-button');
    if (btn3) btn3.removeEventListener('click', this.handleOpenMap);

    window.removeEventListener('geberit.hideMinimapMenu', this.closeMenu);
    window.removeEventListener('onresize', this.handleResize);
  }

  closeMenu() {
    this.openGallery = false;
    this.render();
  }

  clearHTML() {
    this.removeEventListener();
    this.innerHTML = '';
  }

  updateMinimap(event) {
    this.currentMarker = event.detail.viewpointId;
    this.render();
  }

  jumpToView(id) {
    const galleryItem = this._galleryItems.find((el) => el.id === id);
    const viewpointId = galleryItem.viewpoint.id;
    const viewpointPosition = new THREE.Vector3(
      galleryItem.viewpoint.position.x,
      galleryItem.viewpoint.position.y,
      galleryItem.viewpoint.position.z);

    var event = new CustomEvent('loadviewpoint', {
      detail: { viewpointId: viewpointId, viewpointPosition: viewpointPosition },
    });
    window.dispatchEvent(event);
    this.currentMarker = viewpointId;
    this.render();
  }

  render() {
    if (isEmpty(this._content.mapimage)) {
      return;
    }
    this.clearHTML();

    const galleryElement = this.openGallery && document.createElement('gb-minimap-gallery');
    if (galleryElement) {
      galleryElement.galleryItems = this._galleryItems;
      galleryElement.content = this._content;
      this.appendChild(galleryElement);
    }

    const activeItem = this._galleryItems.find((item) => item.viewpoint.id === this.currentMarker);

    let html = `
        ${this.isMobile ?
      `<div class='gb-mobile-menu'>
          <button class='open-grid button-open-gallery'></button>
          <button class='button-open-map'></button>
         </div>` : ''}
            <div class='${this.open ? 'gb-minimap' : 'gb-minimap gb-minimap--hidden'}'>
                <div class='gb-minimap-map'>
                    <img src='${this._content.mapimage}' alt='' 
                    style='width: ${this._content.mapwidth}px; height: ${this.content.mapheight}px;' 
                    />
                ${
      activeItem &&
      `<gb-minimap-activemarker 
          initialrotation='${this.currentRotation}' 
          x='${activeItem.minimapx}' 
          y='${activeItem.minimapy}' 
          rotation='${activeItem.minimaprotation}'>
        </gb-minimap-activemarker>`
    }
                ${this._galleryItems
      .filter((item) => item.viewpoint.id !== this.currentMarker)
      .map(
        (item) => `
                    <div
                        class='gb-minimap-dot'
                        data-id='${item.id}'
                        style='top: ${item.minimapy}px; 
                        left: ${item.minimapx}px;'
                    >
                    </div>
                `)}
                                </div>
      ${!this.isMobile ? `<button class='open-grid button-open-grid'><span>${
        this._content.galleryOpenButton
      }</span></button>`
      : `<button class='close-button'><i class='web20-icon web20-icon-close'></i></button>`}
    </div>`;
    this.insertAdjacentHTML('afterbegin', html);
    this.addEventListener();
  }
}

customElements.define('gb-minimap', GbMinimap);
