import { isEmpty } from '../utils/helper.js';

class GbWebglContent extends HTMLElement {
    constructor() {
        super();
        this._content = { 'root-viewpoint-id': null };
        this.ascene = null;
        this.update = this.update.bind(this);
    }

    set content(value) {
        if (value['root-viewpoint-id'] !== this._content['root-viewpoint-id']) {
            this._content = value;
            this.ascene && this.update();
        }
    }

    get content() {
        return this._content;
    }

    update() {
        this.ascene = document.querySelector('a-scene');
        this.ascene.setAttribute('scene-loader',   { content: this._content });
    }

    connectedCallback() {
        this.ascene = document.createElement('a-scene');
        this.ascene.setAttribute('vr-mode-ui', "enabled: false");
        this.ascene.setAttribute('scene-loader', { content: this._content });
        this.ascene.setAttribute('device-orientation-permission-ui', "enabled: false");
        this.ascene.setAttribute('loading-screen', { backgroundColor: 'black', dotsColor: 'white', enabled: false });

        let cameraRotation = isEmpty(this._content['initial-rotation']) ? 0 : this._content['initial-rotation'];

        this.ascene.innerHTML = `
         <a-assets>
          <img id="viewpoint_loading" src="src/assets/images/panoramas/black.jpg">
          <img id="viewpoint_circle" src="src/assets/images/sprites/viewpoint.png">
          <img id="viewpoint_glow" src="src/assets/images/sprites/gradient.png">
          <img id="hotspot_main" src="src/assets/images/sprites/hotspot-main.png">
          <img id="hotspot_background" src="src/assets/images/sprites/hotspot-background.png">
          <img id="hotspot_plus" src="src/assets/images/sprites/hotspot-plus.png">
        </a-assets>
        <a-camera fov="70" position="0 0 0" rotation="0 ${cameraRotation} 0" mouse-log="height: 1.4" hmdEnabled="false" look-controls-enabled="false" wasd-controls-enabled="false" camera-controls>
        </a-camera>
        <a-entity id="cursor" cursor="fuse: false; rayOrigin: mouse" raycaster="objects: #clickable">
        </a-entity>
        <a-entity panorama="radius: 50.0; src:#viewpoint_loading">
        </a-entity>
        <a-entity id="scene-contents">
        </a-entity>
        `;

        let div = document.createElement('div');
        div.classList.add("webgl-content");
        div.appendChild(this.ascene);
        let html = `<gb-chevrons></gb-chevrons>
                    <div id="info-box-container" class="info-box info-box__container"></div>
                    <gb-loading-indicator></gb-loading-indicator>`;
        div.insertAdjacentHTML('beforeend', html);
        this.appendChild(div);
    }
}

customElements.define('gb-webgl-content', GbWebglContent);