AFRAME.registerComponent('mouse-log',{
    schema: {
        height: { default: 1.4 },
        distance: { default: 5.0 },
        digits: { default: 3 },
    },

    init: function() {
        this.bindMethods();
        window.addEventListener('click', this.onClick);
        window.addEventListener('keydown', this.onKeyDown);
    },

    remove: function() {
        window.removeEventListener('click', this.onClick);
        window.removeEventListener('keydown', this.onKeyDown);
    },

    bindMethods: function() {
        this.onClick = AFRAME.utils.bind(this.onClick, this);
        this.onKeyDown = AFRAME.utils.bind(this.onKeyDown, this);
    },

    onClick: function(event) {
        if(event.shiftKey) {
            this.logPosition(this.getFloorPosition.bind(this));
        }

        if(event.altKey) {
            this.logPosition(this.getSpherePosition.bind(this));
        }
    },

    logPosition: function(positionFunction) {
        // convert camera to normalized viewport position (-1 : 1)
        let mouse = new THREE.Vector2(
            ( event.clientX / window.innerWidth ) * 2 - 1,
            - ( event.clientY / window.innerHeight ) * 2 + 1
        );
        
        // get a reference to the camera
        let camera = document.querySelector('[camera]').getObject3D('camera');

        // create a raycaster and set ray by camera and mouse data
        let raycaster = new THREE.Raycaster();
        raycaster.setFromCamera( mouse, camera );

        // get ray direction
        let direction = raycaster.ray.direction;
        direction.normalize();

        // retrieve the position from the provided function
        let position = positionFunction(direction);

        // convert all digits to the desired length
        let digits = this.data.digits;
        let value = Math.pow(10, digits);
        let output = {
            x: (Math.round(position.x * value) / value).toFixed(digits),
            y: (Math.round(position.y * value) / value).toFixed(digits),
            z: (Math.round(position.z * value) / value).toFixed(digits)
        };

        let output_a = '[json]\n{\n\t"x": '+output.x+',\n\t"y": '+output.y+',\n\t"z": '+output.z+'\n}';
        let output_b = '[component]\n'+output.x+' '+output.y+' '+output.z;

        // log the output value
        console.log(output_a+'\n\n'+output_b);
    },

    getFloorPosition: function(direction) {
        // scale direction vector to the correct length
        let length = -this.data.height / direction.y;
        
        // mulitply direction with length to get the target position
        return direction.multiplyScalar(length);
    },

    getSpherePosition: function(direction) {
        return direction.multiplyScalar(this.data.distance);
    },

    onKeyDown: function(event) {
        const increase = 187;
        const decrease = 189;

        if(event.shiftKey) {
            let increment = 0.1;
            let value = this.data.height;
            if(event.keyCode === increase) {
                value += increment;
            } else if(event.keyCode === decrease) {
                value -= increment;
            }
            if(this.data.height !== value) {
                this.data.height = value;
                console.log('mouse-log -> height: ' + value);
            }
        }

        if(event.altKey) {
            let increment = 1.0;
            let value = this.data.distance;
            if(event.keyCode === increase) {
                value += increment;
            } else if(event.keyCode === decrease) {
                value -= increment;
            }
            if(this.data.distance !== value) {
                this.data.distance = value;
                console.log('mouse-log -> distance: ' + value);
            }
        }


    }
});