class GbVideoPlayer extends HTMLElement {
    constructor() {
        super();
        this.render = this.render.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    connectedCallback() {
        this.classList.add('gb-video-player');
        this.render();
    }

    render() {
        let elementId = this.getAttribute('element-id');
        let videoId = this.getAttribute('video-id');

        this.innerHTML = `
        <div class="gb-video-player__wrapper">
            <div
                id="${elementId}"
                mi24-video-player="true"
                disable-auto-creation="true"
                video-id="${videoId}"
                player-id="AQU2vAEVxB6rHxB5ihuGP8"
                config-type="vmpro"
                flash-path="//e.video-cdn.net/v2/"
                api-url="//d.video-cdn.net/play"
            />
        </div>
        `;

       window.VideoPlayer.Collection.addPlayerById(elementId, {
            success: (playerApi) => {
                playerApi.play();
            },
        });
        window.addEventListener('blur', this.onBlur);
    }

    onBlur() {
        let videoId = this.getAttribute('element-id');
        if (window.VideoPlayer && window.VideoPlayer.Collection.getPlayerById(videoId)) {
            window.VideoPlayer.Collection.getPlayerById(videoId).pause();
        }
    }

    disconnectedCallback() {
        window.removeEventListener('blur', this.onBlur);
    }
}

customElements.define('gb-video-player', GbVideoPlayer);
