import {isEmpty} from "../utils/helper";

class GbIntroduction extends HTMLElement {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this.render = this.render.bind(this);
        this.clearHTML = this.clearHTML.bind(this);
        this.removeListeners = this.removeListener.bind(this);
        this.onSetActive = this.onSetActive.bind(this);
        this._content = {};
        this.afterConnectedCallback = false;
        this.active = false;
    }

    set content(value) {
        this._content = value;
        this.afterConnectedCallback && this.render();
    }

    get content() {
        return this._content;
    }

    connectedCallback() {
        this.classList.add("gb-intro");
        this.classList.add("gb-intro--hidden");
        this.render();
        this.addEventListener();
        this.afterConnectedCallback = true;
    }

    disconnectedCallback() {
        this.removeListener();
    }

    handleClick() {
        this.setActive(false);
    };

    clearHTML() {
        this.removeListener();
        this.innerHTML = '';
    }

    addEventListener() {
        let btn = this.querySelector('button');
        btn.addEventListener("click", this.handleClick);

        window.addEventListener('introductionactive', this.onSetActive);
    }

    removeListener() {
        let btn = this.querySelector('button');
        !isEmpty(btn) && btn.removeEventListener('click', this.handleClick);

        window.removeEventListener('introductionactive', this.onSetActive);
    }

    render() {
        if (isEmpty(this._content)) {
            return;
        }
        this.clearHTML();
        const { title, subtitle, 'btn-label': btnLabel } =  this._content;
        let html = `<div>
                            <gb-backdrop>
                                <gb-modal>
                                    <div class="gb-intro__content">
                                        <h2>${title}<b>${subtitle}</b></h2>
                                        <button class="button button--white">
                                            <i class="web20-icon web20-icon-arrow-link-light-right"></i>
                                            ${btnLabel}
                                        </button>
                                    </div>
                                </gb-modal>
                            </gb-backdrop>
                          </div>`;
        this.insertAdjacentHTML('afterbegin', html);
    }

    onSetActive(event) {
        const { active } = event.detail;
        this.setActive(active);
      }

      setActive(active) {
        if (active === this.active) {
            return;
          }
      
          this.active = active;
          if (active) {
            this.classList.remove("gb-intro--hidden");
          } else {
            this.classList.add("gb-intro--hidden");
          }
      }
}

customElements.define('gb-intro', GbIntroduction);